// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
  display: grid;
  gap: 2px; /* Adjust gap between squares */
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdyaWQtc2l6ZS1zZWxlY3Rvci5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBYTtFQUNiLFFBQVEsRUFBRSwrQkFBK0I7QUFDM0MiLCJmaWxlIjoiZ3JpZC1zaXplLXNlbGVjdG9yLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZ3JpZCB7XHJcbiAgZGlzcGxheTogZ3JpZDtcclxuICBnYXA6IDJweDsgLyogQWRqdXN0IGdhcCBiZXR3ZWVuIHNxdWFyZXMgKi9cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/ux/grid-size-selector/grid-size-selector.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ,EAAE,+BAA+B;AAC3C;;AAEA,oaAAoa","sourcesContent":[".grid {\r\n  display: grid;\r\n  gap: 2px; /* Adjust gap between squares */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
