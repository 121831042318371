// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check-input {
  margin-left: -0.25rem;
}
.form-check-label {
  margin-left: 1rem;
}

.hover-outline:hover {
  border-color: #505050;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LWNoZWNrYm94LmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxxQkFBcUI7QUFDdkI7QUFDQTtFQUNFLGlCQUFpQjtBQUNuQjs7QUFFQTtFQUNFLHFCQUFxQjtBQUN2QiIsImZpbGUiOiJpbnB1dC1jaGVja2JveC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmZvcm0tY2hlY2staW5wdXQge1xyXG4gIG1hcmdpbi1sZWZ0OiAtMC4yNXJlbTtcclxufVxyXG4uZm9ybS1jaGVjay1sYWJlbCB7XHJcbiAgbWFyZ2luLWxlZnQ6IDFyZW07XHJcbn1cclxuXHJcbi5ob3Zlci1vdXRsaW5lOmhvdmVyIHtcclxuICBib3JkZXItY29sb3I6ICM1MDUwNTA7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-checkbox/input-checkbox.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA,gjBAAgjB","sourcesContent":[".form-check-input {\r\n  margin-left: -0.25rem;\r\n}\r\n.form-check-label {\r\n  margin-left: 1rem;\r\n}\r\n\r\n.hover-outline:hover {\r\n  border-color: #505050;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
