// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check {
  margin-top: 8px;
}

.form-check-input {
  margin-left: -0.25rem;
}

.form-check-input-inline {
  margin-left: 0;
}

.form-check-label {
  margin-left: 1rem;
}

.form-check-label-inline {
  margin-left: 0.2rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LXJhZGlvLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFlO0FBQ2pCOztBQUVBO0VBQ0UscUJBQXFCO0FBQ3ZCOztBQUVBO0VBQ0UsY0FBYztBQUNoQjs7QUFFQTtFQUNFLGlCQUFpQjtBQUNuQjs7QUFFQTtFQUNFLG1CQUFtQjtBQUNyQiIsImZpbGUiOiJpbnB1dC1yYWRpby5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmZvcm0tY2hlY2sge1xyXG4gIG1hcmdpbi10b3A6IDhweDtcclxufVxyXG5cclxuLmZvcm0tY2hlY2staW5wdXQge1xyXG4gIG1hcmdpbi1sZWZ0OiAtMC4yNXJlbTtcclxufVxyXG5cclxuLmZvcm0tY2hlY2staW5wdXQtaW5saW5lIHtcclxuICBtYXJnaW4tbGVmdDogMDtcclxufVxyXG5cclxuLmZvcm0tY2hlY2stbGFiZWwge1xyXG4gIG1hcmdpbi1sZWZ0OiAxcmVtO1xyXG59XHJcblxyXG4uZm9ybS1jaGVjay1sYWJlbC1pbmxpbmUge1xyXG4gIG1hcmdpbi1sZWZ0OiAwLjJyZW07XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-radio/input-radio.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA,4vBAA4vB","sourcesContent":[".form-check {\r\n  margin-top: 8px;\r\n}\r\n\r\n.form-check-input {\r\n  margin-left: -0.25rem;\r\n}\r\n\r\n.form-check-input-inline {\r\n  margin-left: 0;\r\n}\r\n\r\n.form-check-label {\r\n  margin-left: 1rem;\r\n}\r\n\r\n.form-check-label-inline {\r\n  margin-left: 0.2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
