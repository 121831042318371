// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unit-inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.unit-input-group {
  flex: 1;
  min-width: 7em;
}

.time-input {
  text-align: center;
  flex: 1 1 auto;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LWR1cmF0aW9uLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFhO0VBQ2IsZUFBZTtFQUNmLFdBQVc7QUFDYjs7QUFFQTtFQUNFLE9BQU87RUFDUCxjQUFjO0FBQ2hCOztBQUVBO0VBQ0Usa0JBQWtCO0VBQ2xCLGNBQWM7QUFDaEIiLCJmaWxlIjoiaW5wdXQtZHVyYXRpb24uY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi51bml0LWlucHV0cy13cmFwcGVyIHtcclxuICBkaXNwbGF5OiBmbGV4O1xyXG4gIGZsZXgtd3JhcDogd3JhcDtcclxuICB3aWR0aDogMTAwJTtcclxufVxyXG5cclxuLnVuaXQtaW5wdXQtZ3JvdXAge1xyXG4gIGZsZXg6IDE7XHJcbiAgbWluLXdpZHRoOiA3ZW07XHJcbn1cclxuXHJcbi50aW1lLWlucHV0IHtcclxuICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbiAgZmxleDogMSAxIGF1dG87XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-duration/input-duration.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,WAAW;AACb;;AAEA;EACE,OAAO;EACP,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA,grBAAgrB","sourcesContent":[".unit-inputs-wrapper {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  width: 100%;\r\n}\r\n\r\n.unit-input-group {\r\n  flex: 1;\r\n  min-width: 7em;\r\n}\r\n\r\n.time-input {\r\n  text-align: center;\r\n  flex: 1 1 auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
