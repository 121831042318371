import { Component, OnInit, OnChanges, OnDestroy, AfterViewInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { MenuItem } from 'primeng/api';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel, ButtonItem, Action, EventModelTyped } from '../../ux-models';
import { ApiOperationType, IApiResponseWrapperTyped, ApiProperties, ApiCall, Query, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { takeUntil } from 'rxjs/operators';
import { CanDoWhat } from 'projects/core-lib/src/lib/models/security';
import { AssetService } from 'projects/core-lib/src/lib/services/asset.service';
import { TableOptions } from '../../table/table-options';
import { TableColumnOptions } from '../../table/table-column-options';
import { IconHelper } from '../../image/icon/icon-helper';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { AlertItemType } from '../../alert/alert-manager';
import { TableHelper } from '../../table/table-helper';
import { Dictionary } from 'projects/core-lib/src/lib/models/dictionary';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { FormGroupFluentModel } from '../../form/form-fluent-model';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { FormBaseClass } from '../../form/form-base-class';
import { UxService } from '../../services/ux.service';
import { AttachmentService } from 'projects/core-lib/src/lib/services/attachment.service';
import { MenuService } from 'projects/core-lib/src/lib/services/menu.service';

/**
 * This makes it sound like it can be used to manage any files but really this only handles
 * attachments for the specified owner type, id, and category.
 */
@Component({
  selector: 'ib-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.css'],
  providers: [FormStatusService]
})
export class FileManagementComponent extends FormBaseClass<m5.AttachmentMetaDataViewModel[]> implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() ownerType: string = null;
  @Input() ownerId: number | string = null;
  @Input() ownerCategory: string = m5.AttachmentConstants.CategoryOther;
  /** The visibility of the upload. */
  @Input() visibility: string = "I";

  // The attachment secondary owner type (if any)
  @Input() secondaryOwnerType: string = null;
  // The attachment secondary owner id (if any)
  @Input() secondaryOwnerId: number | string = null;

  @Input() allowUpload: boolean = undefined;       // Flag if uploads are allowed.  Default is true if user has asset add permission.
  @Input() allowDownload: boolean = undefined;     // Flag if downloads are allowed.  Default is true if user has asset read permission.
  @Input() allowView: boolean = undefined;         // Flag if asset view is allowed.  Default is true if user has asset read permission.
  @Input() allowEdit: boolean = undefined;         // Flag if edit is allowed.  Default is true if user has asset edit permission.
  @Input() allowDelete: boolean = undefined;       // Flag if delete is allowed.  Default is true if user has asset delete permission.
  @Input() allowAddLink: boolean = undefined;      // Flag if add link is allowed.  Default is true if user has asset add permission.

  @Input() addLinkButtonIcon: string = "external-link";
  @Input() addLinkButtonText: string = "Add Link";
  @Input() addLinkButtonColor: string = "primary"; // Options are: default, primary, info, success, warning, danger

  @Input() uploadIcon: string = "cloud-upload (solid)";
  @Input() uploadText: string = "<strong>Drop files or click to upload</strong>";
  @Input() uploadButtonIcon: string = "cloud-upload";
  @Input() uploadButtonText: string = "Upload Files";
  @Input() uploadButtonColor: string = "primary"; // Options are: default, primary, info, success, warning, danger
  /**
  This is a comma separated list of mime types or file extensions. e.g.: image/*,application/pdf,.docx.  Defaults to any file type.
  */
  @Input() acceptedFileTypes: string = null;
  /**
  The maximum file size in MB.  Defaults to 100.
  */
  @Input() maxFileSize: number = 100;

  @Input() availableViews: string[] = ["DET", "ICO", "ILG", "IXL", "GAL"]; // DET = Detail, ICO = Icon, ILG = Icon Large, IXL = Icon Extra Large, GAL = Gallery
  @Input() currentView: string = "DET";

  /* eslint-disable @angular-eslint/no-output-on-prefix */
  // Disabled because we'd have to change a lot of files to use the new syntax
  @Output() onUploadSuccess: EventEmitter<EventModel> = new EventEmitter();
  @Output() onUploadError: EventEmitter<EventModel> = new EventEmitter();
  @Output() onEdit: EventEmitter<EventModel> = new EventEmitter();
  @Output() onEditImage: EventEmitter<EventModel> = new EventEmitter();
  @Output() onDelete: EventEmitter<EventModel> = new EventEmitter();
  @Output() onRefresh: EventEmitter<EventModelTyped<m5.AttachmentMetaDataViewModel[]>> = new EventEmitter();
  /* eslint-enable @angular-eslint/no-output-on-prefix */

  /**
  Toggle flag if upload component is visible.
  */
  uploadVisible: boolean = false;

  filesTableReloadCount: number = 0;
  filesTableOptions: TableOptions;

  /** The context menu for thumbnails when viewing via 'Icons', 'Large', 'Extra Large' */
  fileContextMenu: MenuItem[] = [];
  fileContextMenuAttachment: m5.AttachmentMetaDataViewModel = null;
  fileContactMenuIndex: number = -1;

  galleryImages: any[] = [];
  galleryResponsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  editImage: m5.AttachmentMetaDataViewModel = null;
  /*
   * After editing the image we need to refresh the img elements but they won't refresh without us cache busting
   * the src so append a counter to the url.  Yes this will cache bust all pictures not just the one edited.
   */
  editImageCounter: number = 0;

  /**
  Attachment data
  */
  apiProperties: ApiProperties;
  apiCall: ApiCall;
  loading: boolean = false;
  // public responseScope: m5.IResponseScope = new m5.ResponseScope();

  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected formService: FormStatusService,
    protected attachmentService: AttachmentService,
    protected sanitizer: DomSanitizer,
    protected ngbModalService: NgbModal,
    protected menuService: MenuService) {

    super(appService, uxService, formService, false, null);

  }


  // If any of these are implemented here they need to call super.x() to get the base class implementation executed.
  ngOnInit() {

    super.ngOnInit();

    this.appService.tryGetUser().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      // Default actions are based on the owner type read or edit permissions
      this.setPermissionArea(this.ownerType);
      if (this.allowUpload === undefined) {
        this.allowUpload = this.permissions.edit;
      }
      if (this.allowDownload === undefined) {
        this.allowDownload = this.permissions.read;
      }
      if (this.allowView === undefined) {
        this.allowView = this.permissions.read;
      }
      if (this.allowEdit === undefined) {
        this.allowEdit = this.permissions.edit;
      }
      if (this.allowDelete === undefined) {
        this.allowDelete = this.permissions.edit;
      }
      if (this.allowAddLink === undefined) {
        this.allowAddLink = this.permissions.edit;
      }
    });

    this.filesTableOptions = this.getTableOptions();
    this.fileContextMenu = this.menuService.fromActionListToPrimeMenu(this.filesTableOptions.rowActionButton.options);
    this.apiProperties = Api.Attachment();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
    // We have our own loading indicator specific to this component so set silent for global loading indicators
    this.apiCall.silent = true;
    this.load();

  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.load();
  }

  // ngOnDestroy() {
  //  super.ngOnDestroy();
  // }
  // ngAfterViewInit() {
  //  super.ngAfterViewInit();
  // }

  getTableOptions(): TableOptions {

    const options = new TableOptions();
    options.tableId = null; // no save/load customizations
    options.rowsPerPage = 10;
    options.loadDataFromServer = false;
    options.theme = "striped";

    options.sort = "Title";
    options.columns = [];
    // options.columns.push(new TableColumnOptions("ExternalAssetId", "Id"));
    // options.columns.push(new TableColumnOptions("Title", "Title"));
    options.columns.push(new TableColumnOptions("FriendlyName", "File Name"));
    options.columns.push(new TableColumnOptions("FileType", "File Type", "function"));
    options.columns.slice(-1)[0].render = (row: m5.AttachmentMetaDataViewModel) => {
      const iconDescription = this.attachmentService.getIcon(row.FileType);
      let html = IconHelper.iconDataFromIconDescription(iconDescription, false, true, "", "me-1").html;
      if (Helper.equals(row.FileType, "link", true)) {
        html += "link";
      } else {
        html += row.FileType || "";
      }
      return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    // options.columns.push(new TableColumnOptions("AssetType", "Asset Type", "picklist", Constants.PickList.__Asset_AssetType));
    TableHelper.setAllColumnFilterType(options.columns, "multiselect");
    options.columns.push(new TableColumnOptions("SizeBytes", "File Size", "function"));
    options.columns.slice(-1)[0].filterType = "none";
    options.columns.slice(-1)[0].render = (row: m5.AttachmentMetaDataViewModel) => {
      if (Helper.equals(row.FileType, "link", true)) {
        return "link";
      }
      return this.attachmentService.getSizeDescription(row.FileType, row.SizeBytes, row.SizeOther, row.SizeInformation, row.Width, row.Height);
    };
    options.columns.push(new TableColumnOptions("AddedDateTime", "Created", "date"));
    options.columns.slice(-1)[0].filterType = "none";

    options.rowActionButton = new ButtonItem("", "bars", "default");
    options.rowActionButton.options.push(new Action(CtxMenuIds.open, "Open", "folder-open", "", (event) => {
      this.openFile(this.handleActionEvent(event));
    }));
    options.rowActionButton.options.push(new Action(CtxMenuIds.download, "Download", "download", "", (event) => {
      this.downloadFile(this.handleActionEvent(event));
    }));
    options.rowActionButton.options.push(new Action("divider"));
    options.rowActionButton.options.push(new Action(CtxMenuIds.edit, "Edit", "pencil", "", (event) => {
      this.editFile(this.handleActionEvent(event));
    }));
    options.rowActionButton.options.push(new Action(CtxMenuIds.editImage, "Edit Image", "image", "", (event) => {
      this.editImage = this.handleActionEvent(event);
    }));
    options.rowActionButton.options.slice(-1)[0].visible = (data: m5.AttachmentMetaDataViewModel) => {
      // Only show the 'Edit Image' button if the attachment is an image.
      return Helper.isFileTypeImage(data.FileType);
    };
    options.rowActionButton.options.push(new Action("divider"));
    options.rowActionButton.options.push(new Action(CtxMenuIds.delete, "Delete", "times", "", (event) => {
      this.deleteFile(this.handleActionEvent(event));
    }));

    return options;

  }


  /**
   * This could be an action event for the standard table context menu, or an action event for the p-contextmenu
   * for when icons are right clicked. The same exact options are built for both, it's just converted to prime menu
   * items for the p-contextmenu. The best way I can think to tell the difference is inspect the event object.
   * The standard table event has a data property that is the attachment object. The p-contextmenu
   * just has a pointer event, but we can use the fileContextMenuAttachment which we manually set when an icon is right
   * clicked.
   * @param event
   * @returns
   */
  handleActionEvent(event: any): m5.AttachmentMetaDataViewModel {
    if (event && event.data) {
      return event.data;
    } else {
      return this.fileContextMenuAttachment;
    }
  }


  /**
   *
   * @param cacheIgnore
   * @param manualRefresh if true, the user manually clicked the refresh button
   * @returns
   */
  public load = (cacheIgnore: boolean = false, manualRefresh: boolean = false) => {
    if (!this.apiCall) {
      return;
    }
    this.apiCall.cacheIgnoreOnRead = cacheIgnore;
    // Get the attachment data
    this.loading = true;
    this.apiService.execute(this.apiCall, {
      OwnerResourceType: this.ownerType,
      OwnerResourceId: this.ownerId,
      OwnerResourceCategory: this.ownerCategory
    }).subscribe((response: IApiResponseWrapperTyped<m5.AttachmentMetaDataViewModel[]>) => {
      this.loading = false;
      if (response.Data.Success) {
        if (manualRefresh) {
          const payload = new EventModelTyped<m5.AttachmentMetaDataViewModel[]>("refresh", null, response.Data.Data);
          this.onRefresh.emit(payload);
        }
        this.data = response.Data.Data;
        // console.error("data", this.data);
        this.filesTableReloadCount++;
        this.buildGalleryList();
        // old component kept track of this scope... not sure why
        // this.responseScope = response.Data.Scope;
      } else {
        this.appService.alertManager.addAlertFromApiResponse(response, this.apiCall);
      }
    });
  };


  buildGalleryList() {
    // Now only those that are images make it into our gallery
    this.galleryImages = [];
    this.data.forEach(item => {
      if (this.isImage(item)) {
        const urls = this.attachmentService.getUrls(item);
        this.galleryImages.push({ source: urls.viewUrl, alt: item.FriendlyName, title: item.Summary || item.FriendlyName });
      }
    });
  }

  openFile = (attachment: m5.AttachmentMetaDataViewModel) => {
    if (!attachment) {
      Log.errorMessage("Open was called with null attachment object.");
      return;
    }

    // View the file, but if not supported then download it automatically.
    if (this.attachmentService.isViewable(attachment.FileType)) {
      // const url = this.getUrlString(attachment);
      // this.appService.redirectToWebsiteNewTab(url);
      this.attachmentService.view(attachment, "", true);
    } else {
      this.downloadFile(attachment);
    }
  };

  downloadFile = (attachment: m5.AttachmentMetaDataViewModel) => {
    if (!attachment) {
      Log.errorMessage("Download was called with null attachment object.");
      return;
    }
    this.attachmentService.download(attachment);
  };

  deleteFile = (attachment: m5.AttachmentMetaDataViewModel) => {
    if (!attachment) {
      Log.errorMessage("Delete was called with null attachment object.");
      return;
    }
    const description = attachment.FriendlyName;
    const promise = this.uxService.modal.confirmDelete(`Delete this ${description} file?`, null);
    promise.then((answer) => {
      const apiProp = Api.Attachment();
      const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Delete);
      this.apiService.execute(apiCall, attachment).subscribe((result: IApiResponseWrapper) => {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        if (result.Data.Success) {
          this.load(true);
          const payload: EventModel = new EventModel("delete", null, attachment);
          this.onDelete.emit(payload);
        }
      });
    }, (cancelled) => {
      // No action
    });
  };

  addLink = () => {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    options.title = "Add Link";

    // Build the form
    const group = new FormGroupFluentModel("block");
    group.HasInputString("Description", "Description", "Link", "Description", "W", true);
    group.HasInputString("Link", "Link", "Link", "Link", "W", true);
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Link: { Description: string, Link: string } } = { Link: { Description: "", Link: "" } };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Link: { Description: string, Link: string } }>) => {
      const attachment: m5.AttachmentMetaDataViewModel = new m5.AttachmentMetaDataViewModel();
      attachment.OwnerResourceType = this.ownerType;
      attachment.OwnerResourceId = this.ownerId.toString();
      attachment.OwnerResourceCategory = this.ownerCategory;
      if (this.secondaryOwnerType && this.secondaryOwnerId) {
        attachment.SecondaryOwners.push({
          SecondaryOwnerResourceType: this.secondaryOwnerType,
          SecondaryOwnerResourceId: this.secondaryOwnerId.toString(),
          SecondaryOwnerResourceCategory: this.ownerCategory
        });
      }
      attachment.FileType = "link";
      attachment.Url = event.data.Link.Link;
      attachment.FriendlyName = event.data.Link.Description;
      attachment.Summary = event.data.Link.Description;
      const apiProp: ApiProperties = Api.Attachment();
      const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
      this.apiService.execute(apiCall, attachment).subscribe(response => {
        if (response.Data.Success) {
          this.load(true);
        } else {
          this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "Unable to save link: " + response.Data.Message, 0);
        }
      });
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  };

  editFile = (attachment: m5.AttachmentMetaDataViewModel) => {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    if (Helper.equals(attachment.FileType, "link", true)) {
      options.title = "Edit Link";
    } else {
      options.title = "Edit File";
    }

    // Build the form
    const group = new FormGroupFluentModel("block");
    if (Helper.equals(attachment.FileType, "link", true)) {
      group.HasInputString("Description", "Description", "Attachment", "Description", "W", true);
      group.HasInputString("Link", "Link", "Attachment", "Link", "W", true);
    } else {
      group.HasInputString("File Name", "File Name", "Attachment", "Description", "W", true);
    }
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Attachment: { Description: string, Link: string } } = { Attachment: { Description: attachment.FriendlyName, Link: attachment.Url } };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Attachment: { Description: string, Link: string } }>) => {
      const apiProp: ApiProperties = Api.Attachment();
      const apiRead: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      // Our object here is a list object we need the full object from the server for edits
      this.apiService.execute(apiRead, attachment).subscribe(response => {
        if (response.Data.Success) {
          const model = response.Data.Data;
          if (Helper.equals(attachment.FileType, "link", true)) {
            model.Url = event.data.Attachment.Link;
            model.FriendlyName = event.data.Attachment.Description;
          } else {
            model.FriendlyName = event.data.Attachment.Description;
          }
          const apiEdit: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Edit);
          this.apiService.execute(apiEdit, model).subscribe(response2 => {
            if (response2.Data.Success) {
              this.load(true);
              const payload2: EventModel = new EventModel("edit", null, response.Data.Data);
              this.onEdit.emit(payload2);
            } else {
              this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "Unable to save attachment: " + response.Data.Message, 0);
            }
          });
        } else {
          this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "Unable to save attachment: " + response.Data.Message, 0);
        }
      });
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  };


  getUrl(attachment: m5.AttachmentMetaDataViewModel, thumbnail: boolean = false): string | SafeUrl {
    if (!attachment) {
      return "";
    }
    const urls = this.attachmentService.getUrls(attachment, this.editImageCounter);
    if (thumbnail) {
      return urls.viewThumbnailUrlSafe;
    }
    return urls.viewUrlSafe;
  }

  getUrlString(attachment: m5.AttachmentMetaDataViewModel): string {
    if (!attachment) {
      return "";
    }
    const urls = this.attachmentService.getUrls(attachment, this.editImageCounter);
    return urls.viewUrl;
  }

  isImage(attachment: m5.AttachmentMetaDataViewModel): boolean {
    if (!attachment) {
      return false;
    }
    return Helper.isFileTypeImage(attachment.FileType);
  }

  hasThumbnail(attachment: m5.AttachmentMetaDataViewModel): boolean {
    if (attachment?.FileNameThumbnail) {
      return true;
    }
    return false;
  }

  getIcon(attachment: m5.AttachmentMetaDataViewModel): string {
    if (!attachment) {
      return "";
    }
    return this.attachmentService.getIcon(attachment.FileType);
  }

  onFileAreaClick($event) {
    // Someone clicked in our context menu area but not a file so clear any file context
    this.fileContextMenuAttachment = null;
    this.fileContactMenuIndex = -1;
  }

  onFileClick($event, attachment: m5.AttachmentMetaDataViewModel, index: number) {

    if (!attachment) {
      this.fileContextMenuAttachment = null;
      this.fileContactMenuIndex = -1;
      return;
    }

    if (Helper.htmlEventIsRightMouseButton($event)) {
      this.fileContextMenuAttachment = attachment;
      this.fileContactMenuIndex = index;

      const editImageIndex = this.fileContextMenu.findIndex(x => x.id === CtxMenuIds.editImage);
      if (editImageIndex === -1) {
        console.error("Attempted to show or hide 'Edit Image' context menu item but it was not found.");
      } else {
        if (Helper.isFileTypeImage(attachment.FileType)) {
          this.fileContextMenu[editImageIndex].visible = true;
        } else {
          this.fileContextMenu[editImageIndex].visible = false;
        }
      }

      try {
        // Don't let event propagate up to onFileAreaClick()
        $event.stopPropagation();
        $event.preventDefault();
      } catch (err) {
        // Log.errorMessage(err);
      }
    } else {
      this.fileContextMenuAttachment = null;
      this.fileContactMenuIndex = -1;
    }

  }

  fireUploadSuccess($event) {
    // console.error("upload success", $event);
    // Reload data
    this.load(true);
    // Fire registered event handler(s)
    const payload: EventModel = new EventModel("upload", $event);
    this.onUploadSuccess.emit(payload);
    this.appService.alertManager.addAlertMessage(AlertItemType.Success, "Successfully uploaded.", 3);
    if ($event?.cargo?.countPending === 0) {
      this.uploadVisible = false;
    }
  }

  fireUploadError($event) {
    // console.error("upload error", $event);
    // Fire registered event handler(s)
    const payload: EventModel = new EventModel("upload", $event);
    this.onUploadError.emit(payload);
  }

  onImageEditSuccess($event) {
    // console.error("onImageEditSuccess: ", $event);
    this.editImageCounter++;
    this.load(true);
    const payload: EventModel = new EventModel("editImage", null, $event.data, null, null);
    this.onEditImage.emit(payload);
  }

  onAttachmentEditModalClose($event) {
    // Need to null this or the modal won't reopen when we try to edit the next image (or same one)
    this.editImage = null;
  }

}
class CtxMenuIds {
  static readonly open: string = "open";
  static readonly download: string = "download";
  static readonly edit: string = "edit";
  static readonly editImage: string = "editImage";
  static readonly delete: string = "delete";
}