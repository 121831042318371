// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.design-mode {
  border: 1px dotted blue;
  margin: 1px;
  min-height: 20px;
}

.design-mode-icon {
  float: right;
  /*visually this looks better but jacks up our clickable area => margin-left: -15px;*/
  line-height: 0.5rem;
  font-size: 0.75rem;
  color: blue;
  cursor: pointer;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvcm0tY29udHJvbC1yZW5kZXIuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHVCQUF1QjtFQUN2QixXQUFXO0VBQ1gsZ0JBQWdCO0FBQ2xCOztBQUVBO0VBQ0UsWUFBWTtFQUNaLG9GQUFvRjtFQUNwRixtQkFBbUI7RUFDbkIsa0JBQWtCO0VBQ2xCLFdBQVc7RUFDWCxlQUFlO0FBQ2pCIiwiZmlsZSI6ImZvcm0tY29udHJvbC1yZW5kZXIuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5kZXNpZ24tbW9kZSB7XHJcbiAgYm9yZGVyOiAxcHggZG90dGVkIGJsdWU7XHJcbiAgbWFyZ2luOiAxcHg7XHJcbiAgbWluLWhlaWdodDogMjBweDtcclxufVxyXG5cclxuLmRlc2lnbi1tb2RlLWljb24ge1xyXG4gIGZsb2F0OiByaWdodDtcclxuICAvKnZpc3VhbGx5IHRoaXMgbG9va3MgYmV0dGVyIGJ1dCBqYWNrcyB1cCBvdXIgY2xpY2thYmxlIGFyZWEgPT4gbWFyZ2luLWxlZnQ6IC0xNXB4OyovXHJcbiAgbGluZS1oZWlnaHQ6IDAuNXJlbTtcclxuICBmb250LXNpemU6IDAuNzVyZW07XHJcbiAgY29sb3I6IGJsdWU7XHJcbiAgY3Vyc29yOiBwb2ludGVyO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/form-render/form-control-render/form-control-render.component.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,oFAAoF;EACpF,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB;;AAEA,41BAA41B","sourcesContent":[".design-mode {\r\n  border: 1px dotted blue;\r\n  margin: 1px;\r\n  min-height: 20px;\r\n}\r\n\r\n.design-mode-icon {\r\n  float: right;\r\n  /*visually this looks better but jacks up our clickable area => margin-left: -15px;*/\r\n  line-height: 0.5rem;\r\n  font-size: 0.75rem;\r\n  color: blue;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
