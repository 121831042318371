// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control-tags {
  height: unset;
}
.form-control {
  padding: 0%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LXRhZ3MuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQWE7QUFDZjtBQUNBO0VBQ0UsV0FBVztBQUNiIiwiZmlsZSI6ImlucHV0LXRhZ3MuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5mb3JtLWNvbnRyb2wtdGFncyB7XHJcbiAgaGVpZ2h0OiB1bnNldDtcclxufVxyXG4uZm9ybS1jb250cm9sIHtcclxuICBwYWRkaW5nOiAwJTtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-tags/input-tags.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;;AAEA,oZAAoZ","sourcesContent":[".form-control-tags {\r\n  height: unset;\r\n}\r\n.form-control {\r\n  padding: 0%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
