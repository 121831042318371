// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nowrap {
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LXRleHRhcmVhLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBZ0I7RUFDaEIscUJBQXFCO0VBQ3JCLGtCQUFrQjtBQUNwQiIsImZpbGUiOiJpbnB1dC10ZXh0YXJlYS5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm5vd3JhcCB7XHJcbiAgd2hpdGUtc3BhY2U6IHByZTtcclxuICBvdmVyZmxvdy13cmFwOiBub3JtYWw7XHJcbiAgb3ZlcmZsb3cteDogc2Nyb2xsO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-textarea/input-textarea.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA,gbAAgb","sourcesContent":[".nowrap {\r\n  white-space: pre;\r\n  overflow-wrap: normal;\r\n  overflow-x: scroll;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
