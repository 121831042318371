// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pre {
  margin-bottom: 0;
}

code {
  font-size: 90%; /*11px!important;*/
}

  code.small {
    font-size: 80%;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvZGUtYmxvY2suY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGdCQUFnQjtBQUNsQjs7QUFFQTtFQUNFLGNBQWMsRUFBRSxrQkFBa0I7QUFDcEM7O0VBRUU7SUFDRSxjQUFjO0VBQ2hCIiwiZmlsZSI6ImNvZGUtYmxvY2suY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbInByZSB7XHJcbiAgbWFyZ2luLWJvdHRvbTogMDtcclxufVxyXG5cclxuY29kZSB7XHJcbiAgZm9udC1zaXplOiA5MCU7IC8qMTFweCFpbXBvcnRhbnQ7Ki9cclxufVxyXG5cclxuICBjb2RlLnNtYWxsIHtcclxuICAgIGZvbnQtc2l6ZTogODAlO1xyXG4gIH1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/dev/code-block/code-block.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc,EAAE,kBAAkB;AACpC;;EAEE;IACE,cAAc;EAChB;;AAEF,ohBAAohB","sourcesContent":["pre {\r\n  margin-bottom: 0;\r\n}\r\n\r\ncode {\r\n  font-size: 90%; /*11px!important;*/\r\n}\r\n\r\n  code.small {\r\n    font-size: 80%;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
