import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UxService } from 'projects/common-lib/src/lib/services/ux.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AssetService } from './asset.service';
import { Helper, Log } from '../helpers/helper';
import { ApiCall, ApiOperationType, ApiProperties, ApiResponse, IApiResponse } from '../api/ApiModels';
import { Api } from '../api/Api';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { EventElementModel, EventModel } from 'projects/common-lib/src/lib/ux-models';
import { ApiHelper } from '../api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { Subject } from 'rxjs';

// No { providedIn: 'root' } since we want unique instances per parent component
// On the parent component specify "providers: [FileUploadService]" so that component
// and any children get the same instance of this service so we can know information
// about all forms on the component.
@Injectable()
export class FileUploadService {

  // Counters to use for displaying stats
  counters: UploadCounters = {
    countPending: 0,
    countStarted: 0,
    countUploading: 0,
    countDone: 0,
    countSuccess: 0,
    countError: 0,
  };

  /*
   * By default we are uploading files to the server.  If the file is intended
   * to be intercepted by the web application and handled locally then this should
   * be set to false assets using properties assigned here.  This scenario should
   * handle the file event which will be fired when a new file is submitted.
   */
  upload: boolean = true;

  /**
   * A config object to be passed to the dropzone component. Contains some of their
   * config properties as well as some custom event emitters to suit our needs.
   * https://docs.dropzone.dev/configuration/basics/configuration-options
   */
  config: any = {};

  private uploadSuccessSubject: Subject<EventModel> = new Subject<EventModel>();
  /** Emits when a file is uploaded successfully */
  uploadSuccess$ = this.uploadSuccessSubject.asObservable();

  private uploadErrorSubject: Subject<EventModel> = new Subject<EventModel>();
  /** Emits when there is an error when attempting to upload a file. */
  uploadError$ = this.uploadErrorSubject.asObservable();

  private localFilePostedSubject: Subject<EventModel> = new Subject<EventModel>();
  /** Emits when the 'onLoad' on the FileReader component is called. */
  localFilePosted$ = this.localFilePostedSubject.asObservable();

  private uploadCountersSubject: Subject<UploadCounters> = new Subject<UploadCounters>();
  /** Emits when the counters are changed. */
  uploadCounters$ = this.uploadCountersSubject.asObservable();

  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected assetService: AssetService,
    protected sanitizer: DomSanitizer) {
    try {
      (window as any).Dropzone.autoDiscover = false;
    } catch (err) {
      console.error("Error trying to turn off dropzone auto discover", err);
    }
  }


  configureDropZone(settings: FileUploadConfigurationSettings): any {

    if (!settings) {
      Log.errorMessage("Unable to build drop zone configuration due to missing file upload configuration settings object.");
      return {};
    }

    const self = this;

    let dictDefaultMessage: string = ""; // "<i class='fa fa-cloud-upload fa-2x fa-fw faa-vertical animated-hover'></i>&nbsp;&nbsp;<strong>Drop files or click to upload</strong>";
    if (settings.uploadIcon) {
      dictDefaultMessage += `<i class='${IconHelper.parseIcon(settings.uploadIcon).calculatedClasses} fa-2x fa-fw faa-vertical animated-hover'></i>`;
    }
    if (settings.uploadIcon && settings.uploadText) {
      dictDefaultMessage += "&nbsp;&nbsp;";
    }
    if (settings.uploadText) {
      dictDefaultMessage += settings.uploadText;
    }

    let targetUrl: string | SafeUrl = settings.uploadUrl;
    if (!targetUrl) {
      targetUrl = this.buildFileUploadUrl(settings.uploadType, settings.ownerType, settings.ownerId, settings.category, settings.attachmentId);
    }
    if (!targetUrl) {
      // We will get a "No URL provided" exception thrown breaking the dropzone config process so give some url.
      // If we're trying to process a file locally that will be done in the the code below where we define the
      // accept function.
      targetUrl = "/";
    }

    const token: string = ApiHelper.getAuthToken();
    const headers: any = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    } else {
      let apiKey: string = ApiHelper.getAuthKey();
      if (!apiKey) {
        apiKey = this.appService.config.anonymousApiKey;
      }
      headers["X-Auth-Key"] = apiKey;
    }
    headers["X-Trace"] = true;
    headers["X-Api-Version"] = "" + this.appService.config.apiVersion;

    this.config = {

      url: targetUrl,
      maxFilesize: settings.maxFileSize,
      parallelUploads: settings.parallelUploads,
      headers: headers,
      timeout: settings.timeout,
      dictDefaultMessage: dictDefaultMessage,
      disablePreviews: settings.disablePreviews,
      clickable: settings.clickable,

      autoProcessQueue: settings.upload,

      success: (file: any, response: IApiResponse) => {
        // Update counts
        this.counters.countUploading--;
        if (this.counters.countUploading < 0) {
          this.counters.countUploading = 0;
        }
        this.counters.countDone++;
        this.counters.countSuccess++;

        this.uploadCountersSubject.next(this.counters);

        // Clear list cache as appropriate
        if (settings.uploadType === "Attachment") {
          this.apiService.cache.cacheRemoveValue(Api.Attachment().cacheName, "http", true);
        } else if (settings.uploadType === "Asset") {
          this.apiService.cache.cacheRemoveValue(Api.Asset().cacheName, "http", true);
        }

        // Sometimes onSuccess() isn't enough as it may be abstracted away by a few layers so broadcast our count for those who care to know (e.g. forms that require uploads, etc.)
        // this.$rootScope.$broadcast("AttachmentCount", this.countSuccess);
        // already called via component event binding ... this.fireSuccess({ file: file: response: response });
        try {
          // Scroll to bottom
          const dzDiv = document.getElementsByClassName("dz-wrapper");
          if (dzDiv && dzDiv.length > 0) {
            dzDiv[0].scrollTop = dzDiv[0].scrollHeight;
          }
        } catch (err) {
          console.error(err);
        }

        const payload: EventModel = new EventModel("success", response, file, null, null);
        this.uploadSuccessSubject.next(payload);

      },

      error: (file: any, response: IApiResponse) => {
        // Update counts
        // Client side errors have response of type string instead of IApiResponse
        // and means the file was never pending upload so we decrement pending
        // instead of uploading counters.
        let message: string = "Upload failed";
        if (ApiHelper.isApiResponse(response)) {
          this.counters.countUploading--;
          if (this.counters.countUploading < 0) {
            this.counters.countUploading = 0;
          }
          if (typeof response === "string") {
            // Response object that was serialized so parse from json to our object
            try {
              response = JSON.parse(response);
            } catch {
              response = new ApiResponse();
              response.Success = false;
              response.ResultCode = 100;
              response.Message = "Upload failed";
            }
            message = response.Message;
          }
        } else {
          this.counters.countPending--;
          if (this.counters.countPending < 0) {
            this.counters.countPending = 0;
          }
          message = <string><any>response;
          // Build a response object that can be used for our callback which won't be happy with a dropzone string
          response = new ApiResponse();
          response.Success = false;
          response.ResultCode = 100;
          response.Message = message;
        }
        this.counters.countDone++;
        this.counters.countError++;

        this.uploadCountersSubject.next(this.counters);

        // already called via component event binding ... this.fireError({ file: file, response: response });
        const results = [];

        // If previews are disabled, the 'previewElement' property will not exist on the 'file' variable
        if (!settings.disablePreviews) {
          file.previewElement.classList.add("dz-error");
          const ref = file.previewElement.querySelectorAll("[data-dz-errormessage]");
          let len = 0;
          len = ref.length;

          for (let i = 0; i < len; i++) {
            const node = ref[i];
            results.push(node.textContent = message);
          }
        }

        try {
          // Scroll to bottom
          // The element with the dz-wrapper class may not exist because we remove it from the dom when we want
          // a hidden dropzone
          const dzDiv = document.getElementsByClassName("dz-wrapper");
          if (dzDiv && dzDiv.length > 0) {
            dzDiv[0].scrollTop = dzDiv[0].scrollHeight;
          }
        } catch (err) {
          console.error(err);
        }

        const payload: EventModel = new EventModel("error", response, file, null, null);
        this.uploadErrorSubject.next(payload);

        return results;
      },

      sending: (file, xhr, formData) => {
        // Update counts
        this.counters.countPending--;
        this.counters.countStarted++;
        this.counters.countUploading++;

        this.uploadCountersSubject.next(this.counters);

        // Will send the specified data with the file upload
        let data: any = settings.uploadData;
        if (!data && settings.uploadType !== "Other") {
          data = this.buildFileUploadData(settings);
        }
        if (data) {
          // Attach our JSON object to the form data being submitted via POST
          formData.append("Data", JSON.stringify(data));
        }
      },

      init: function () {
        // Don't overwrite built in addedfile functionality as it will jack up the component
        this.on("addedfile", function (file) {
          self.counters.countPending++;
          self.uploadCountersSubject.next(this.counters);
          // If we're not uploading then incur the overhead of getting file contents to fire off file event
          if (!self.upload) {
            // console.error("ready to read file", file);
            const reader = new FileReader();
            // reader.addEventListener("loadend", (event) => {
            //  //console.error(event.target.result);
            //  self.fireFile(file, event.target.result);
            // });
            reader.onload = (event) => {
              // var contents = event.target.result;
              // console.log("File contents: " + contents);
              // console.error('ready to raise file upload event');
              self.fireFile(file, event.target.result);
              // console.error('done with raise file upload event');
            };
            reader.onerror = (event) => {
              Log.errorMessage(`File could not be read! Code ${event?.target?.error?.code}.`);
            };
            reader.readAsText(file);
            // console.error('file read');
            // console.error("reader",reader);
            self.uxService.detectChanges();
          }
        });
      }

    };


    // If upload type is other and no url is configured for it then we are
    // trying to process the files locally and we'll use the accept() function
    // to accomplish that.  DropZone used to work without this accept() function
    // being used but they started requiring a URL be defined and the only
    // way to prevent it trying to upload to that URL is via accept().
    if (settings.uploadType === "Other" && !settings.uploadUrl) {
      this.config.accept = (file, done) => {
        try {
          const promise = Helper.getFileContents(file);
          promise.then((answer) => {
            self.fireFile(file, answer.contents);
          }, (rejected: ProgressEvent<FileReader>) => {
            Log.errorMessage(`File could not be read: ${rejected?.target?.error?.message}.`);
            console.error(rejected?.target?.error);
          });
          self.uxService.detectChanges();
        } catch (error) {
          Log.errorMessage(`File could not be read: ${error.message}.`);
          console.error(error);
        }
        //done("Ready to process this file.");
        // Don't call done() so the file never gets uploaded.

      };
    }


    // If attaching files to an existing asset id then we have a few differences
    // like sending this to a different url and not attaching JSON data that is
    // used when creating a new asset entry with the file.
    if (settings.uploadType === "Asset" && settings.assetId) {
      this.config.url = this.assetService.buildFileUploadUrl(settings.assetId, false);
      this.config.sending = (file, xhr, formData) => {
        // Update counts
        this.counters.countPending--;
        this.counters.countStarted++;
        this.counters.countUploading++;
        this.uploadCountersSubject.next(this.counters);
      };
    }

    // Don't create this config key unless we have a value we're assigning
    if (settings.acceptedFileTypes) {
      this.config.acceptedFiles = settings.acceptedFileTypes;
    }

    // console.error(this.config);

    return this.config;

  }


  public fireFile(file: any, contents: any) {
    const data: any = { file: file, contents: contents };
    // if (Helper.isArray(event)) {
    //  if (event.length >= 2) {
    //    data = event[1];
    //  }
    // }
    const payload: EventModel = new EventModel("file", event, data, new EventElementModel("upload"), this.counters);
    this.localFilePostedSubject.next(payload);
    // console.error(payload);
  }


  buildFileUploadUrl(uploadType: "Asset" | "Attachment" | "Other", ownerType: string, ownerId: string | number, ownerCategory: string, attachmentId: string = ""): SafeUrl | string {

    let url: string = "";
    if (uploadType === "Attachment") {
      const apiProp: ApiProperties = Api.AttachmentUpload();
      const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, (attachmentId ? ApiOperationType.Edit : ApiOperationType.Add));
      url = ApiHelper.buildApiAbsoluteUrl(apiCall, {
        OwnerResourceType: ownerType,
        OwnerResourceId: ownerId,
        OwnerResourceCategory: ownerCategory || m5.AttachmentConstants.CategoryOther,
        AttachmentId: attachmentId
      });
      url = ApiHelper.addQueryStringToUrl(url, `token=${apiCall.token}`);
    } else if (uploadType === "Asset") {
      const apiProp: ApiProperties = Api.AssetAddFromUpload();
      const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
      url = ApiHelper.buildApiAbsoluteUrl(apiCall, {});
      url = ApiHelper.addQueryStringToUrl(url, `token=${apiCall.token}`);
    } else if (uploadType === "Other") {
      Log.debugMessage("File upload type set to 'Other' so custom url is required or upload action must being handled inside the file event.");
      return url;
    } else {
      Log.errorMessage(`Unable to build url for upload type "${uploadType}".`);
      return "";
    }
    // return this.sanitizer.bypassSecurityTrustUrl(url);
    return url;
  }

  /**
   * This will hide the dropzone's message box and change the css so the striped border will
   * be set to none, rendering the dropzone invisible.
   * @param dropzoneDivId a unique id assigned to the dropzone that should be hidden
   */
  hideDropzone(dropzoneDivId: string): boolean {
    let dropzoneElement: HTMLElement = null;
    let dropzoneWrapperElement: HTMLElement = null;
    let dropzoneMessageElement: Element = null;

    dropzoneElement = document.getElementById(dropzoneDivId);

    if (dropzoneElement) {
      dropzoneWrapperElement = dropzoneElement.querySelector('.dz-wrapper');
      dropzoneMessageElement = dropzoneElement.querySelector('.dz-message');

      if (dropzoneWrapperElement) {
        dropzoneWrapperElement.classList.remove('dz-wrapper');
        dropzoneWrapperElement.style.border = "none";
      } else {
        // onInit, the dz-wrapper class exists but if the config gets changed, like when the
        // ownerId changes and so the config.url gets updated, it causes the message div to appear
        // again, but the dz-wrapper class is still gone, so just search for it on the dropzoneElement
        // in case it's back
        dropzoneMessageElement = dropzoneElement.querySelector('.dz-message');
      }

      if (dropzoneMessageElement) {
        dropzoneMessageElement.remove();
      }

      return true;
    }

    // The dropzone element was not found, so return false, maybe the caller can try again in case it
    // hasn't loaded yet.
    return false;
  }


  resetCounters() {
    this.counters.countDone = 0;
    this.counters.countError = 0;
    this.counters.countPending = 0;
    this.counters.countStarted = 0;
    this.counters.countSuccess = 0;
    this.counters.countUploading = 0;
  }

  protected buildFileUploadData(settings: FileUploadConfigurationSettings): m5.AttachmentMetaDataViewModel | m5.AssetEditViewModel {
    if (!settings) {
      Log.errorMessage("Upload settings object not defined so file upload url cannot be configured.");
      return null;
    }
    if (settings.uploadType === "Attachment") {
      const data = new m5.AttachmentMetaDataViewModel();
      if (settings.attachmentId) {
        data.AttachmentId = settings.attachmentId;
      }
      if (settings.ownerType || settings.ownerId) {
        data.OwnerResourceType = settings.ownerType;
        data.OwnerResourceId = settings.ownerId?.toString();
      }
      data.OwnerResourceCategory = settings.category || m5.AttachmentConstants.CategoryOther;
      if (settings.secondaryOwnerType || settings.secondaryOwnerId) {
        const secondary = new m5.AttachmentMetaDataSecondaryOwnerViewModel();
        secondary.SecondaryOwnerResourceType = settings.secondaryOwnerType;
        secondary.SecondaryOwnerResourceId = settings.secondaryOwnerId?.toString();
        secondary.SecondaryOwnerResourceCategory = settings.category || m5.AttachmentConstants.CategoryOther;
        data.SecondaryOwners.push(secondary);
      }
      data.Summary = settings.summary || "";
      data.Description = settings.description || "";
      data.Status = settings.status || "";
      data.Tags = settings.tags || [];
      data.IsPublic = settings.visibility === "P";
      return data;
    } else if (settings.uploadType === "Asset") {
      const data = new m5.AssetEditViewModel();
      if (settings.assetId) {
        data.AssetId = settings.assetId;
      }
      if (settings.parentAssetId) {
        data.ParentAssetId = settings.parentAssetId;
      }
      if (settings.ownerType || settings.ownerId) {
        data.OwnerResourceType = settings.ownerType;
        data.OwnerResourceId = settings.ownerId as number;
      }
      if (settings.secondaryOwnerType || settings.secondaryOwnerId) {
        data.SecondaryOwnerResourceType = settings.secondaryOwnerType;
        data.SecondaryOwnerResourceId = settings.secondaryOwnerId as number;
      }
      data.ShortDescription = settings.summary || "";
      data.FullDescription = settings.description || "";
      data.AssetGroup = settings.group || "";
      data.SystemAssetGroup = settings.systemGroup || "";
      data.AssetCategory = settings.category || "";
      data.AssetClass = settings.class || "";
      data.AssetScope = settings.scope || "";
      data.Visibility = settings.visibility || "I";
      data.Tags = settings.tags || [];
      return data;
    } else {
      Log.errorMessage(`Unable to build data for upload type "${settings.uploadType}".`);
      return null;
    }
  }




}


export interface FileUploadConfigurationSettings {

  /*
   * By default we are uploading files to the server.  If the file is intended
   * to be intercepted by the web application and handled locally then this should
   * be set to false.  This scenario should handle the file event which will be
   * fired when a new file is submitted.
   */
  upload: boolean;

  /*
   * The type of upload being done.  If set to 'Other' then the uploadUrl must
   * set along with any optional upload data.
   */
  uploadType: "Asset" | "Attachment" | "Other";

  /*
   * The url for uploading.  Optional unless the upload type is 'Other'.
   */
  uploadUrl: string;

  /*
   * If upload type is 'Other' or if complete control is desired over the
   * upload data regardless of upload type set the upload data here.
   */
  uploadData: any;




  /**
   * The owner type for uploads expecting this value.
   */
  ownerType: string;

  /**
   * The owner id for uploads expecting this value.
   */
  ownerId: number | string;

  /**
   * An optional secondary owner type for uploads expecting this value.
   */
  secondaryOwnerType: string;

  /**
   * An optional secondary owner id for uploads expecting this value.
   */
  secondaryOwnerId: number | string;




  /**
   * Upload icon.  For example: "cloud-upload (solid)"
   */
  uploadIcon: string;

  /**
   * Upload text.  For example: "<strong>Drop files or click to upload</strong>"
   */
  uploadText: string;

  /**
   * This is a comma separated list of mime types or file extensions. e.g.: image/*,application/pdf,.docx.
   * If not specified then any file type is accepted.
   */
  acceptedFileTypes: string;

  /**
   * The maximum file size in MB.  For example: 100.
   */
  maxFileSize: number;

  /**
   * The maximum number of files to upload in parallel.  For example: 5.
   */
  parallelUploads: number;

  /**
   * If this is true, no thumbnail preview will show for the file that was uploaded.
   */
  disablePreviews?: boolean;

  /**
   * If this is true, clicking on the area where a dropzone is will open the file explorer.
   */
  clickable?: boolean;

  /**
   * The timeout for uploads to complete.
   * Note that if using dropzone the dropzone component defaults to 30 seconds which may be too
   * quick for some of the server side processing that might happen.
   * Suggested default is 180000 (180,000 ms = 180 seconds = 3 minutes).
   */
  timeout: number;





  // Properties commonly used for populating upload data:

  /**
   * A summary for the file being uploaded for upload types that expect a summary.
   */
  summary: string;

  /**
   * A description for the file being uploaded for upload types that expect a description.
   */
  description: string;

  /**
   * A group for the file being uploaded for upload types that expect a group.
   */
  group: string;

  /**
   * A system group for the file being uploaded for upload types that expect a system group.
   */
  systemGroup: string;

  /**
   * A category for the file being uploaded for upload types that expect a category.
   */
  category: string;

  /**
   * A class for the file being uploaded for upload types that expect a class.
   */
  class: string;

  /**
   * A visibility setting for the file being uploaded for upload types that expect a visibility setting.
   */
  visibility: string;

  /**
   * A scope for the file being uploaded for upload types that expect a scope.
   */
  scope: string;

  /**
   * A status for the file being uploaded for upload types that expect a status.
   */
  status: string;

  /**
   * A list of tags for the file being uploaded for upload types that expect tags.
   */
  tags: string[];




  /**
   * If uploading to an existing attachment this is the attachment id.
   */
  attachmentId: string;

  /**
   * If uploading to an existing asset this is the asset id.
   */
  assetId: number;

  /**
   * If uploading child assets this is the parent asset id.
   */
  parentAssetId: number;

}


export interface UploadCounters {
  countPending: number;
  countStarted: number;
  countUploading: number;
  countDone: number;
  countSuccess: number;
  countError: number;
}
