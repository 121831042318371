// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-input {
  flex: 4 1 auto;
}
.time-input {
  text-align: center;
  flex: 1 1 auto;
}

.flex-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-input-date {
  flex: 1;
  min-width: 9em;
}
.flex-input-time {
  flex: 1;
  min-width: 13em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LWRhdGUuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQWM7QUFDaEI7QUFDQTtFQUNFLGtCQUFrQjtFQUNsQixjQUFjO0FBQ2hCOztBQUVBO0VBQ0UsYUFBYTtFQUNiLGVBQWU7RUFDZixXQUFXO0FBQ2I7O0FBRUE7RUFDRSxPQUFPO0VBQ1AsY0FBYztBQUNoQjtBQUNBO0VBQ0UsT0FBTztFQUNQLGVBQWU7QUFDakIiLCJmaWxlIjoiaW5wdXQtZGF0ZS5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmRhdGUtaW5wdXQge1xyXG4gIGZsZXg6IDQgMSBhdXRvO1xyXG59XHJcbi50aW1lLWlucHV0IHtcclxuICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbiAgZmxleDogMSAxIGF1dG87XHJcbn1cclxuXHJcbi5mbGV4LWlucHV0LXdyYXBwZXIge1xyXG4gIGRpc3BsYXk6IGZsZXg7XHJcbiAgZmxleC13cmFwOiB3cmFwO1xyXG4gIHdpZHRoOiAxMDAlO1xyXG59XHJcblxyXG4uZmxleC1pbnB1dC1kYXRlIHtcclxuICBmbGV4OiAxO1xyXG4gIG1pbi13aWR0aDogOWVtO1xyXG59XHJcbi5mbGV4LWlucHV0LXRpbWUge1xyXG4gIGZsZXg6IDE7XHJcbiAgbWluLXdpZHRoOiAxM2VtO1xyXG59Il19 */`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-date/input-date.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;AACb;;AAEA;EACE,OAAO;EACP,cAAc;AAChB;AACA;EACE,OAAO;EACP,eAAe;AACjB;AACA,g3BAAg3B","sourcesContent":[".date-input {\r\n  flex: 4 1 auto;\r\n}\r\n.time-input {\r\n  text-align: center;\r\n  flex: 1 1 auto;\r\n}\r\n\r\n.flex-input-wrapper {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  width: 100%;\r\n}\r\n\r\n.flex-input-date {\r\n  flex: 1;\r\n  min-width: 9em;\r\n}\r\n.flex-input-time {\r\n  flex: 1;\r\n  min-width: 13em;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
