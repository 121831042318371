// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';
import * as m5core from './ngModelsCore5';
import * as m5sec from './ngModelsSecurity5';


/**
 * @name DashboardWidgetActionEditViewModel
 */
export class DashboardWidgetActionEditViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DefaultAction
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultAction: boolean = false;

    /**
     * @name ShowAsButton
     * @servertype Boolean
     * @type {boolean}
     */
    ShowAsButton: boolean = false;

    /**
     * @name ShowAsButtonIconOnly
     * @servertype Boolean
     * @type {boolean}
     */
    ShowAsButtonIconOnly: boolean = false;

    /**
     * @name ShowAsMenuItem
     * @servertype Boolean
     * @type {boolean}
     */
    ShowAsMenuItem: boolean = false;

    /**
     * @name ShowAsContextMenuItem
     * @servertype Boolean
     * @type {boolean}
     */
    ShowAsContextMenuItem: boolean = false;

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Style
     * @servertype String
     * @type {string}
     */
    Style: string = "";

    /**
     * @name SelectedStyle
     * @servertype String
     * @type {string}
     */
    SelectedStyle: string = "";

    /**
     * @name SetWidgetTitle
     * @servertype String
     * @type {string}
     */
    SetWidgetTitle: string = "";

    /**
     * @name Action
     * @servertype String
     * @type {string}
     */
    Action: string = "";

    /**
     * @name CustomAction
     * @servertype Boolean
     * @type {boolean}
     */
    CustomAction: boolean = false;

    /**
     * @name Download
     * @servertype Boolean
     * @type {boolean}
     */
    Download: boolean = false;

    /**
     * @name OpenInNewWindow
     * @servertype Boolean
     * @type {boolean}
     */
    OpenInNewWindow: boolean = false;

    /**
     * @name QueryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    QueryId: number = 0;

    /**
     * @name QueryName
     * @servertype String
     * @type {string}
     */
    QueryName: string = "";

    /**
     * @name QueryResultsFormat
     * @servertype String
     * @type {string}
     */
    QueryResultsFormat: string = "";

    /**
     * @name QueryResultsFileName
     * @servertype String
     * @type {string}
     */
    QueryResultsFileName: string = "";

    /**
     * @name QueryResultsTitle
     * @servertype String
     * @type {string}
     */
    QueryResultsTitle: string = "";

    /**
     * @name QueryResultsIncludeAsOfTime
     * @servertype Boolean
     * @type {boolean}
     */
    QueryResultsIncludeAsOfTime: boolean = false;

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name AssetName
     * @servertype String
     * @type {string}
     */
    AssetName: string = "";

    /**
     * @name Url
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name Configuration
     * @servertype System.Object
     * @type {any}
     */
    Configuration: any = {};

}



/**
 * @name DashboardListViewModel
 */
export class DashboardListViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ValidContactTypes
     * @servertype string array
     * @type {string[]}
     */
    ValidContactTypes: string[] = [];

    /**
     * @name ValidGroupIds
     * @servertype long array
     * @type {number[]}
     */
    ValidGroupIds: number[] = [];

    /**
     * @name InScopeReason
     * @servertype String
     * @type {string}
     */
    InScopeReason: string = "";

}



/**
 * @name DashboardEditViewModel
 */
export class DashboardEditViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name OwnerContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerContactId: number = null;

    /**
     * @name ValidContactTypes
     * @servertype string array
     * @type {string[]}
     */
    ValidContactTypes: string[] = [];

    /**
     * @name ValidGroupIds
     * @servertype long array
     * @type {number[]}
     */
    ValidGroupIds: number[] = [];

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Configuration
     * @servertype System.Object
     * @type {any}
     */
    Configuration: any = {};

    /**
     * @name Pages
     * @servertype DashboardPageEditViewModel array
     * @type {DashboardPageEditViewModel[]}
     */
    Pages: DashboardPageEditViewModel[] = [];

    /**
     * @name RotateDashboardPages
     * @servertype Boolean
     * @type {boolean}
     */
    RotateDashboardPages: boolean = false;

    /**
     * @name RotateDashboardSeconds
     * @servertype Int32
     * @type {number}
     */
    RotateDashboardSeconds: number = 0;

    /**
     * @name RotateDashboardPageIds
     * @servertype string array
     * @type {string[]}
     */
    RotateDashboardPageIds: string[] = [];

    /**
     * @name Locked
     * @servertype Boolean
     * @type {boolean}
     */
    Locked: boolean = false;

}



/**
 * @name DashboardPageEditViewModel
 */
export class DashboardPageEditViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name TemplateId
     * @servertype String
     * @type {string}
     */
    TemplateId: string = "";

    /**
     * @name TemplatePageId
     * @servertype String
     * @type {string}
     */
    TemplatePageId: string = "";

    /**
     * @name TemplatePageVersion
     * @servertype Int32
     * @type {number}
     */
    TemplatePageVersion: number = 0;

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Configuration
     * @servertype System.Object
     * @type {any}
     */
    Configuration: any = {};

    /**
     * @name Widgets
     * @servertype DashboardPageWidgetEditViewModel array
     * @type {DashboardPageWidgetEditViewModel[]}
     */
    Widgets: DashboardPageWidgetEditViewModel[] = [];

    /**
     * @name Locked
     * @servertype Boolean
     * @type {boolean}
     */
    Locked: boolean = false;

}



/**
 * @name DashboardPageWidgetEditViewModel
 */
export class DashboardPageWidgetEditViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name PageId
     * @servertype String
     * @type {string}
     */
    PageId: string = "";

    /**
     * @name WidgetId
     * @servertype String
     * @type {string}
     */
    WidgetId: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RefreshShowIcon
     * @servertype Boolean
     * @type {boolean}
     */
    RefreshShowIcon: boolean = false;

    /**
     * @name RefreshIntervalSeconds
     * @servertype Int32
     * @type {number}
     */
    RefreshIntervalSeconds: number = 0;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Width
     * @servertype Int32
     * @type {number}
     */
    Width: number = 0;

    /**
     * @name Height
     * @servertype Int32
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name MinWidth
     * @servertype Int32
     * @type {number}
     */
    MinWidth: number = 0;

    /**
     * @name MinHeight
     * @servertype Int32
     * @type {number}
     */
    MinHeight: number = 0;

    /**
     * @name MaxWidth
     * @servertype Int32
     * @type {number}
     */
    MaxWidth: number = 0;

    /**
     * @name MaxHeight
     * @servertype Int32
     * @type {number}
     */
    MaxHeight: number = 0;

    /**
     * @name Row
     * @servertype Int32
     * @type {number}
     */
    Row: number = 0;

    /**
     * @name Column
     * @servertype Int32
     * @type {number}
     */
    Column: number = 0;

    /**
     * @name RowSm
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RowSm: number = 0;

    /**
     * @name ColumnSm
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ColumnSm: number = 0;

    /**
     * @name RowMd
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RowMd: number = 0;

    /**
     * @name ColumnMd
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ColumnMd: number = 0;

    /**
     * @name RowLg
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RowLg: number = 0;

    /**
     * @name ColumnLg
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ColumnLg: number = 0;

    /**
     * @name RowXl
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RowXl: number = 0;

    /**
     * @name ColumnXl
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ColumnXl: number = 0;

    /**
     * @name Actions
     * @servertype DashboardWidgetActionEditViewModel array
     * @type {DashboardWidgetActionEditViewModel[]}
     */
    Actions: DashboardWidgetActionEditViewModel[] = [];

    /**
     * @name SelectedAction
     * @servertype String
     * @type {string}
     */
    SelectedAction: string = "";

    /**
     * @name Contents
     * @servertype String
     * @type {string}
     */
    Contents: string = "";

    /**
     * @name Configuration
     * @servertype System.Object
     * @type {any}
     */
    Configuration: any = {};

    /**
     * @name Widget
     * @servertype IB.Data.Model.v5.DashboardWidgetEditViewModel
     * @type {DashboardWidgetEditViewModel}
     */
    Widget: DashboardWidgetEditViewModel = null;

    /**
     * @name Locked
     * @servertype Boolean
     * @type {boolean}
     */
    Locked: boolean = false;

}



/**
 * @name DashboardWidgetEditViewModel
 */
export class DashboardWidgetEditViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Category
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RefreshShowIcon
     * @servertype Boolean
     * @type {boolean}
     */
    RefreshShowIcon: boolean = false;

    /**
     * @name RefreshIntervalSeconds
     * @servertype Int32
     * @type {number}
     */
    RefreshIntervalSeconds: number = 0;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name PropertyList
     * @servertype DashboardWidgetPropertyEditViewModel array
     * @type {DashboardWidgetPropertyEditViewModel[]}
     */
    PropertyList: DashboardWidgetPropertyEditViewModel[] = [];

    /**
     * @name WidgetTemplate
     * @servertype String
     * @type {string}
     */
    WidgetTemplate: string = "";

    /**
     * @name WidgetTemplateUrl
     * @servertype String
     * @type {string}
     */
    WidgetTemplateUrl: string = "";

    /**
     * @name WidgetTemplateAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WidgetTemplateAssetId: number = 0;

    /**
     * @name WidgetPropertiesEditorTemplateUrl
     * @servertype String
     * @type {string}
     */
    WidgetPropertiesEditorTemplateUrl: string = "";

    /**
     * @name ValidForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDirectory: boolean = false;

    /**
     * @name ValidForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForCustomer: boolean = false;

    /**
     * @name ValidForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForVendor: boolean = false;

    /**
     * @name ValidForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAgent: boolean = false;

    /**
     * @name ValidForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForServiceProvider: boolean = false;

    /**
     * @name ValidForPermissions
     * @servertype PermissionEditViewModel array
     * @type {m5sec.PermissionEditViewModel[]}
     */
    ValidForPermissions: m5sec.PermissionEditViewModel[] = [];

    /**
     * @name Width
     * @servertype Int32
     * @type {number}
     */
    Width: number = 0;

    /**
     * @name Height
     * @servertype Int32
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name MinWidth
     * @servertype Int32
     * @type {number}
     */
    MinWidth: number = 0;

    /**
     * @name MinHeight
     * @servertype Int32
     * @type {number}
     */
    MinHeight: number = 0;

    /**
     * @name MaxWidth
     * @servertype Int32
     * @type {number}
     */
    MaxWidth: number = 0;

    /**
     * @name MaxHeight
     * @servertype Int32
     * @type {number}
     */
    MaxHeight: number = 0;

    /**
     * @name NewWidgetAutoShowSettings
     * @servertype Boolean
     * @type {boolean}
     */
    NewWidgetAutoShowSettings: boolean = false;

    /**
     * @name Actions
     * @servertype DashboardWidgetActionEditViewModel array
     * @type {DashboardWidgetActionEditViewModel[]}
     */
    Actions: DashboardWidgetActionEditViewModel[] = [];

    /**
     * @name ModulesAny
     * @servertype string array
     * @type {string[]}
     */
    ModulesAny: string[] = [];

    /**
     * @name ModulesAll
     * @servertype string array
     * @type {string[]}
     */
    ModulesAll: string[] = [];

    /**
     * @name Configuration
     * @servertype System.Object
     * @type {any}
     */
    Configuration: any = {};

}



/**
 * @name DashboardWidgetPropertyEditViewModel
 */
export class DashboardWidgetPropertyEditViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name PageLabel
     * @servertype String
     * @type {string}
     */
    PageLabel: string = "";

    /**
     * @name Label
     * @servertype String
     * @type {string}
     */
    Label: string = "";

    /**
     * @name Property
     * @servertype String
     * @type {string}
     */
    Property: string = "";

    /**
     * @name Width
     * @servertype String
     * @type {string}
     */
    Width: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name DescriptionProperty
     * @servertype String
     * @type {string}
     */
    DescriptionProperty: string = "";

    /**
     * @name Options
     * @servertype PickListSelectionViewModel array
     * @type {m5core.PickListSelectionViewModel[]}
     */
    Options: m5core.PickListSelectionViewModel[] = [];

    /**
     * @name Children
     * @servertype DashboardWidgetPropertyEditViewModel array
     * @type {DashboardWidgetPropertyEditViewModel[]}
     */
    Children: DashboardWidgetPropertyEditViewModel[] = [];

    /**
     * @name Configuration
     * @servertype System.Object
     * @type {any}
     */
    Configuration: any = {};

}



/**
 * @name DashboardWidgetPropertyBookmarkEditViewModel
 */
export class DashboardWidgetPropertyBookmarkEditViewModel {

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Url
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Background
     * @servertype String
     * @type {string}
     */
    Background: string = "";

    /**
     * @name OpenUrlInNewWindow
     * @servertype Boolean
     * @type {boolean}
     */
    OpenUrlInNewWindow: boolean = false;

    /**
     * @name GroupHeading
     * @servertype Boolean
     * @type {boolean}
     */
    GroupHeading: boolean = false;

}



/**
 * @name WidgetType Enum
 * @readonly
 * @enum {number}
 */
export enum WidgetType {

    /**
     * Asset Filtered List
     */
    AssetFilteredList = 0,

    /**
     * Inventory Expiring Maintenance
     */
    InventoryExpiringMaintenance = 1,

    /**
     * Inventory Expiring Warranty
     */
    InventoryExpiringWarranty = 2,

    /**
     * Inventory Expiring Lease
     */
    InventoryExpiringLease = 3,

    /**
     * Cases Assigned To Me
     */
    CasesAssignedToMe = 4,

    /**
     * Cases Owned By Me But Assigned To Others
     */
    CasesOwnedByMeButAssignedToOthers = 5,

    /**
     * Cases Assigned To My Groups
     */
    CasesAssignedToMyGroups = 6,

    /**
     * Possible Duplicates
     */
    PossibleDuplicates = 7,

    /**
     * Query Results As Tiles
     */
    QueryResultsAsTiles = 8,

    /**
     * Query Results As Table
     */
    QueryResultsAsTable = 9,

    /**
     * Query Results As Chart
     */
    QueryResultsAsChart = 10,

    /**
     * Query Results As Table And Chart
     */
    QueryResultsAsTableAndChart = 11,

    /**
     * Download Buttons
     */
    DownloadButtons = 12,

    /**
     * Bookmarks
     */
    Bookmarks = 13,

    /**
     * Case Insights Personal
     */
    CaseInsightsPersonal = 14,

    /**
     * Case Insights Group
     */
    CaseInsightsGroup = 15,

    /**
     * Invoices Past Due
     */
    InvoicesPastDue = 16,

    /**
     * Invoices Due Soon
     */
    InvoicesDueSoon = 17,

    /**
     * Invoices Overpaid
     */
    InvoicesOverpaid = 18,

    /**
     * Payments Voucher
     */
    PaymentsVoucher = 19,

    /**
     * Top Customers
     */
    TopCustomers = 20,

    /**
     * Invoices Partially Paid
     */
    InvoicesPartiallyPaid = 21,

    /**
     * Invoices Recently Issued
     */
    InvoicesRecentlyIssued = 22,

    /**
     * Largest Outstanding Balances
     */
    LargestOutstandingBalances = 23

}



/**
 * @name FavoriteEditViewModel
 * @description
 * The Favorite table contains list of contact favorite objects.
 */
export class FavoriteEditViewModel {

    /**
     * @name FavoriteId
     * @description
     * The id of the favorite.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FavoriteId: number = null;

    /**
     * @name FavoriteResourceType
     * @description
     * The favorite resource type (e.g. table, etc.) for this favorite.
     * @servertype String
     * @type {string}
     */
    FavoriteResourceType: string = "";

    /**
     * @name FavoriteResourceId
     * @description
     * The favorite resource id is the key of the favorite resource type for this favorite.  For example, if the favorite resource
     * type is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FavoriteResourceId: number = 0;

    /**
     * @name FavoriteResourceId2
     * @description
     * The favorite resource id 2 is the key of the favorite resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    FavoriteResourceId2: string = "";

    /**
     * @name ContactId
     * @description
     * The contact id that favorites this item.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FavoriteRank
     * @description
     * The rank applied to this favorite.  It is up to each resource type to determine what ranks are valid and how ranking is interpreted.
     *  At its simplest this value could be ignored and the default rank of 0 means the item is a favorite.  It could also be used
     * for a numeric score or to love (10), like (1), dislike (-1), hate (-10) an item.  The context of what the favorite rank means
     * is fully dependent on the resource type.  If large numbers are used to represent static ranks like Love (2000000002), Like
     * (2000000001), Dislike (-2000000001), Hate (-2000000002) then that leaves lower ranges open for natural favorite scoring based
     * on usage patterns for machine learning of what resources are 'liked' more than others.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FavoriteRank: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name FormControlEditViewModel
 * @description
 * The form control table contains configuration information for controls that are part of a form.
 */
export class FormControlEditViewModel {

    /**
     * @name FormControlId
     * @description
     * The id of the form control.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormControlId: number = null;

    /**
     * @name FormControlGroupId
     * @description
     * The id of the form control group this belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormControlGroupId: number = null;

    /**
     * @name FormId
     * @description
     * The id of the form this belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormId: number = null;

    /**
     * @name Description
     * @description
     * A description for this form control.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the control is enabled.  If disabled it is not rendered.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ObjectName
     * @description
     * The name of the object this control will reference.
     * @servertype String
     * @type {string}
     */
    ObjectName: string = "";

    /**
     * @name ObjectChildName
     * @servertype String
     * @type {string}
     */
    ObjectChildName: string = "";

    /**
     * @name ObjectGrandchildName
     * @servertype String
     * @type {string}
     */
    ObjectGrandchildName: string = "";

    /**
     * @name ObjectFullName
     * @servertype String
     * @type {string}
     */
    ObjectFullName: string = "";

    /**
     * @name PropertyName
     * @description
     * The name of the property belonging to the object that this control will reference.
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

    /**
     * @name PropertyDescriptionName
     * @description
     * If the property has a companion property that provides a description this is the property name of that description property.
     *  For example, ContactId property may have a companion property ContactName, AssetId may have AssetTitle, RoleId may have
     * RoleDescription, etc.
     * @servertype String
     * @type {string}
     */
    PropertyDescriptionName: string = "";

    /**
     * @name PropertyDataType
     * @description
     * The data type of this property.  Possible values include:
     * String = String
     * Integer = Integer
     * Float = Float
     * DateTime = DateTime
     * Date = Date
     * Time = Time
     * Boolean = Boolean
     * ListString = List of Strings
     * ListObject = List of Objects
     * Object = Object
     * Other = Other
     * Unknown = Unknown
     * @servertype String
     * @type {string}
     */
    PropertyDataType: string = "String";

    /**
     * @name ControlType
     * @description
     * Type of control.  Possible options include:
     * Text = Text (Text from the contents value or asset id.)
     * HTML = HTML (HTML text from the contents value or asset id.)
     * Image = Image (Image from the asset id or asset url.)
     * Video = Video (Video from the asset id or asset url.)
     * Attachment = Attachment (Attachment to be associated with the specified object.)
     * FormButtons = Form Buttons (Buttons defined for the form - e.g. Save and Cancel buttons.)
     * Button = Button (Button to execute the click event.  Button text is taken from contents value.  Save buttons are automatically
     * added for the form and are not needed as a control.)
     * Form = Form (An embedded form referenced in the embedded form id.)
     * Editor = Editor (An embedded dynamic editor referenced in the embedded form id.)
     * InputText = Text Input (A single line text input.)
     * InputNumber = Number Input (A numeric input.)
     * InputTags = Tags Input (Tags for the specified object.)
     * InputTextArea = Text Area Input (A multiple line text area input.)
     * InputRichText = Text Area Input With Rich Text Option (A multiple line text area input.)
     * InputStringList = String List Input (A list of strings input.)
     * InputSelect = Select Input (A drop down selection input.)
     * InputMultiSelect = MultiSelect Input (A multiple selection input.)
     * InputListBox = List Box Input (A list selection input.)
     * InputCheckbox = Check Box Input (A check box input.)
     * InputRadio = Radio Input (A radio input.)
     * InputDateTime = Date Time Input (A date time picker input.)
     * InputContact = Contact Input (A contact picker input.)
     * InputAsset = Asset Input (An asset picker input.)
     * InputStatic = Static Input (Text is taken from the contents value and formatted to appear as an input control.)
     * Content = Content (Content represented by a content id.)
     * Component = Component (Component represented by a component name.)
     * @servertype String
     * @type {string}
     */
    ControlType: string = "InputText";

    /**
     * @name ControlOption
     * @description
     * An option setting for the control type.  Possible options include:
     * For standard control type for multiple choice values:
     * 1 = 1 column of radio buttons
     * 2 = 2 columns of radio buttons
     * 3 = 3 columns of radio buttons
     * H = Horizontal list of radio buttons
     * D = Drop down list of options (default)
     * For text:
     * 1 = Heading 1
     * 2 = Heading 2
     * 3 = Heading 3
     * 4 = Heading 4
     * 5 = Heading 5
     * 6 = Heading 6
     * P = Paragraph (default)
     * For button:
     * P = Primary
     * 2 = Secondary
     * L = Light
     * K = Dark
     * I = Info
     * S = Success
     * W = Warning
     * D = Danger
     * N = Normal (default)
     * For attachment:
     * U = Upload (default)
     * M = Manager
     * Other control types do not have any options to select:
     *   = N/A
     * @servertype String
     * @type {string}
     */
    ControlOption: string = "";

    /**
     * @name ControlProperties
     * @description
     * For controls that have properties this contains those properties in json format.  For example:
     * InputContact has properties like { ContactTypes: ["D", "G"] }
     * @servertype String
     * @type {string}
     */
    ControlProperties: string = "";

    /**
     * @name ControlScope
     * @description
     * The control scope for this control.  This can help with scenarios where form groups are mostly the same between add and edit
     * mode with only a few controls being different.  Possible values include:
     * L = List (control is for list layout)
     * A = Add (control is for add form)
     * E = Edit (control is for edit form)
     * B = Both (control is for both add and edit forms)
     * @servertype String
     * @type {string}
     */
    ControlScope: string = "B";

    /**
     * @name DisplayWhenExpression
     * @description
     * When provided this represents an expression that is used to determine when the control should be displayed.  If this is a
     * property in ObjectName.PropertyName format the data is checked for this value and, when not empty, the control is displayed.
     *  If this is a {{template}} then the data is evaluated using the template expression and, when the expression returns true
     * the control is displayed.
     * @servertype String
     * @type {string}
     */
    DisplayWhenExpression: string = "";

    /**
     * @name OptionsPickList
     * @servertype PickListSelectionViewModel array
     * @type {m5core.PickListSelectionViewModel[]}
     */
    OptionsPickList: m5core.PickListSelectionViewModel[] = [];

    /**
     * @name OptionsPickListId
     * @description
     * For control types that utilize option pick lists for selection, typeahead, etc. this is an optional pick list id that represents
     * the available options.
     * @servertype String
     * @type {string}
     */
    OptionsPickListId: string = "";

    /**
     * @name OptionsPickListFilter
     * @description
     * If a filter is needed for the options pick list this is the filter value.
     * @servertype String
     * @type {string}
     */
    OptionsPickListFilter: string = "";

    /**
     * @name OptionsValueIsInteger
     * @description
     * When true and options are being presented this indicates the value should be treated as an integer.
     * @servertype Boolean
     * @type {boolean}
     */
    OptionsValueIsInteger: boolean = false;

    /**
     * @name OptionsIncludeNone
     * @description
     * When true and options are being presented this indicates if 'none' should be an option.
     * @servertype Boolean
     * @type {boolean}
     */
    OptionsIncludeNone: boolean = true;

    /**
     * @name OptionsNoneLabel
     * @description
     * For control types that utilize option pick lists and need a none option this is the label to use for that none option.
     * @servertype String
     * @type {string}
     */
    OptionsNoneLabel: string = "<None>";

    /**
     * @name Contents
     * @description
     * Contents for non-standard controls (e.g. text, html, buttons, etc.).  If the control type is content this is the content
     * id that represents that content.  If the control type is component this is the component name.
     * @servertype String
     * @type {string}
     */
    Contents: string = "";

    /**
     * @name AssetUrl
     * @description
     * For control types that reference an asset this is the optional url to the resource to be used for that control.  For those
     * control types the AssetUrl or AssetId is required.
     * @servertype String
     * @type {string}
     */
    AssetUrl: string = "";

    /**
     * @name AssetType
     * @description
     * For control types that reference an asset this is the optional asset type to the resource to be used for that element (e.g.
     * mime type) to be used in conjunction with AssetUrl.
     * @servertype String
     * @type {string}
     */
    AssetType: string = "";

    /**
     * @name AssetId
     * @description
     * The asset id of the asset to use for the control types that reference an asset.  For those control types the AssetUrl or
     * AssetId is required.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = null;

    /**
     * @name AssetTitle
     * @servertype String
     * @type {string}
     */
    AssetTitle: string = "";

    /**
     * @name EmbeddedFormId
     * @description
     * If the control is an embedded form this is the form id of the embedded form.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    EmbeddedFormId: number = null;

    /**
     * @name InheritLayout
     * @description
     * When true control layout elements are inherited from the form group or form.
     * @servertype Boolean
     * @type {boolean}
     */
    InheritLayout: boolean = true;

    /**
     * @name Label
     * @description
     * The label to show for this control.
     * @servertype String
     * @type {string}
     */
    Label: string = "";

    /**
     * @name LabelPrefix
     * @description
     * A prefix rendered directly before the label.  This can be used for things like * asterisk prefix to indicate a required field
     * or other custom prefix indicators.  The prefix is not translated and provides the ability for the label that is translated
     * to share translations that otherwise would have to be repeated due to the prefix.  For example, a 'Customer' translation
     * can be reused where a '*Customer:' label would otherwise need a repeated translation.
     * @servertype String
     * @type {string}
     */
    LabelPrefix: string = "";

    /**
     * @name LabelSuffix
     * @description
     * A suffix rendered directly after the label.  This can be used for things like : colon suffix as a label and control separator
     * or other custom suffix indicators.  The suffix is not translated and provides the ability for the label that is translated
     * to share translations that otherwise would have to be repeated due to the suffix.  For example, a 'Customer' translation
     * can be reused where a '*Customer:' label would otherwise need a repeated translation.
     * @servertype String
     * @type {string}
     */
    LabelSuffix: string = "";

    /**
     * @name Icon
     * @description
     * The icon to show instead of label for this control.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Watermark
     * @description
     * The watermark to show for this control if the specified control type supports showing a watermark.
     * @servertype String
     * @type {string}
     */
    Watermark: string = "";

    /**
     * @name HelpTitle
     * @description
     * Optional help title to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    HelpTitle: string = "";

    /**
     * @name HelpText
     * @description
     * Optional help text to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    HelpText: string = "";

    /**
     * @name Alignment
     * @description
     * The alignment for presentation to the end user.  Possible values include:
     * L = Left
     * R = Right
     * C = Center
     * @servertype String
     * @type {string}
     */
    Alignment: string = "L";

    /**
     * @name Width
     * @description
     * The general width for presentation to the end user.  Possible values include:
     * T = Tiny
     * N = Narrow
     * M = Medium
     * W = Wide
     * F = Full (no label)
     * @servertype String
     * @type {string}
     */
    Width: string = "W";

    /**
     * @name Height
     * @description
     * The height for presentation to the end user.  For multi-line text data types this is the number of rows tall for the input
     * control.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name LabelLayout
     * @description
     * The layout to use for control labels.  Possible values include:
     * D = Default
     * L = Left
     * T = Top
     * H = Hidden
     * @servertype String
     * @type {string}
     */
    LabelLayout: string = "D";

    /**
     * @name LabelBold
     * @description
     * When true control labels are bold.
     * @servertype Boolean
     * @type {boolean}
     */
    LabelBold: boolean = false;

    /**
     * @name CharacterCountTemplate
     * @description
     * A template used for character count information.  This can include placeholders {{ActualLength}} for the current count, {{MaximumLength}}
     * for the maximum number of characters, and {{RemainingLength}} for the number of characters remaining.
     * @servertype String
     * @type {string}
     */
    CharacterCountTemplate: string = "";

    /**
     * @name ContainerClasses
     * @description
     * A space delimited list of classes used on the control container for presentation to the end user (e.g. CSS classes).  If
     * a template expression the expression is evaluated to get the desired values.
     * @servertype String
     * @type {string}
     */
    ContainerClasses: string = "";

    /**
     * @name LabelClasses
     * @description
     * A space delimited list of classes used on the label control for presentation to the end user (e.g. CSS classes).  If a template
     * expression the expression is evaluated to get the desired values.
     * @servertype String
     * @type {string}
     */
    LabelClasses: string = "";

    /**
     * @name ControlClasses
     * @description
     * A space delimited list of classes used on the control for presentation to the end user (e.g. CSS classes).  If a template
     * expression the expression is evaluated to get the desired values.
     * @servertype String
     * @type {string}
     */
    ControlClasses: string = "";

    /**
     * @name ContainerStyles
     * @description
     * A semicolon delimited list of styles used on the control container for presentation to the end user (e.g. CSS styles).  If
     * a template expression the expression is evaluated to get the desired values.
     * @servertype String
     * @type {string}
     */
    ContainerStyles: string = "";

    /**
     * @name LabelStyles
     * @description
     * A semicolon delimited list of styles used on the label control for presentation to the end user (e.g. CSS styles).  If a
     * template expression the expression is evaluated to get the desired values.
     * @servertype String
     * @type {string}
     */
    LabelStyles: string = "";

    /**
     * @name ControlStyles
     * @description
     * A semicolon delimited list of styles used on the control for presentation to the end user (e.g. CSS styles).  If a template
     * expression the expression is evaluated to get the desired values.
     * @servertype String
     * @type {string}
     */
    ControlStyles: string = "";

    /**
     * @name Mask
     * @description
     * The mask used to apply to data input controls.  Possible values include:
     * A = Any alpha character.
     * 9 = Any numeric character.
     * * = Any alphanumeric character.
     * All other characters are treated as literals.
     * @servertype String
     * @type {string}
     */
    Mask: string = "";

    /**
     * @name ReadOnly
     * @description
     * When true the control is read-only.
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name Disabled
     * @description
     * When true the control is disabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Disabled: boolean = false;

    /**
     * @name AutoFocus
     * @description
     * When true the control is set to automatically get focus.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoFocus: boolean = false;

    /**
     * @name Standalone
     * @description
     * When true the control is considered a standalone control that will not impact the overall status of the form being valid,
     * dirty, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    Standalone: boolean = false;

    /**
     * @name Sortable
     * @description
     * When true the list column is sortable.
     * @servertype Boolean
     * @type {boolean}
     */
    Sortable: boolean = true;

    /**
     * @name Resizable
     * @description
     * When true the list column is resizable.
     * @servertype Boolean
     * @type {boolean}
     */
    Resizable: boolean = true;

    /**
     * @name Wrap
     * @description
     * When true the list column has word-wrap enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Wrap: boolean = true;

    /**
     * @name AllowLineBreaks
     * @description
     * When true the list column allows line breaks.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowLineBreaks: boolean = false;

    /**
     * @name AllowMarkUp
     * @description
     * When true the list column allows text mark up.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowMarkUp: boolean = false;

    /**
     * @name IncludeInGlobalFilter
     * @description
     * When true the list column is included in global filters.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeInGlobalFilter: boolean = true;

    /**
     * @name FilterType
     * @description
     * The filter type to use for this column.  Supported values include:
     * None = No filter for this column
     * Text = Text input filter
     * Select = Select input filter
     * Multiselect = Multiselect input filter
     * @servertype String
     * @type {string}
     */
    FilterType: string = "";

    /**
     * @name FilterMatchMode
     * @description
     * The filter match mode to use for this column.  Supported values include:
     * Contains = Contains
     * StartsWith = Starts With
     * EndsWith = Ends With
     * In = In
     * Equals = Equals
     * NotEquals = Not Equals
     * LT = Less Than
     * LTE = Less Than or Equal To
     * GT = Greater Than
     * GTE = Greater Than or Equal To
     * @servertype String
     * @type {string}
     */
    FilterMatchMode: string = "";

    /**
     * @name FilterPickListId
     * @description
     * The pick list id to use for filter selection.
     * @servertype String
     * @type {string}
     */
    FilterPickListId: string = "";

    /**
     * @name PrefixIcon
     * @description
     * If the control has a prefix this is a prefix icon to use (if any).
     * @servertype String
     * @type {string}
     */
    PrefixIcon: string = "";

    /**
     * @name PrefixText
     * @description
     * If the control has a prefix this is a prefix text to use (if any).
     * @servertype String
     * @type {string}
     */
    PrefixText: string = "";

    /**
     * @name PrefixHelpText
     * @description
     * Optional help text for the prefix to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    PrefixHelpText: string = "";

    /**
     * @name PrefixClickEvent
     * @description
     * The event to fire when the control prefix item is clicked.
     * @servertype String
     * @type {string}
     */
    PrefixClickEvent: string = "";

    /**
     * @name SuffixIcon
     * @description
     * If the control has a suffix this is a suffix icon to use (if any).
     * @servertype String
     * @type {string}
     */
    SuffixIcon: string = "";

    /**
     * @name SuffixText
     * @description
     * If the control has a suffix this is a suffix text to use (if any).
     * @servertype String
     * @type {string}
     */
    SuffixText: string = "";

    /**
     * @name SuffixHelpText
     * @description
     * Optional help text for the suffix to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    SuffixHelpText: string = "";

    /**
     * @name SuffixClickEvent
     * @description
     * The event to fire when the control suffix item is clicked.
     * @servertype String
     * @type {string}
     */
    SuffixClickEvent: string = "";

    /**
     * @name SuffixIcon2
     * @description
     * If the control has a suffix this is a suffix icon to use (if any).
     * @servertype String
     * @type {string}
     */
    SuffixIcon2: string = "";

    /**
     * @name SuffixText2
     * @description
     * If the control has a suffix this is a suffix text to use (if any).
     * @servertype String
     * @type {string}
     */
    SuffixText2: string = "";

    /**
     * @name SuffixHelpText2
     * @description
     * Optional help text for the suffix to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    SuffixHelpText2: string = "";

    /**
     * @name SuffixClickEvent2
     * @description
     * The event to fire when the control suffix item is clicked.
     * @servertype String
     * @type {string}
     */
    SuffixClickEvent2: string = "";

    /**
     * @name Required
     * @description
     * When true the control is required to have a value.
     * @servertype Boolean
     * @type {boolean}
     */
    Required: boolean = false;

    /**
     * @name IncludeRequiredIcon
     * @description
     * When true and required, this setting determines if the required icon is displayed or not.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeRequiredIcon: boolean = true;

    /**
     * @name ErrorMessageRequired
     * @description
     * The error message to use when a valid is required but not provided.
     * @servertype String
     * @type {string}
     */
    ErrorMessageRequired: string = "";

    /**
     * @name Minimum
     * @description
     * The minimum value (int), length (string), file count (attachments) of the value for this control.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Minimum: number = 0;

    /**
     * @name ErrorMessageMinimum
     * @description
     * The error message to use when a control has an unmet minimum.  {{MinimumLength}} and {{ActualLength}} placeholders are available
     * for use in the error message.
     * @servertype String
     * @type {string}
     */
    ErrorMessageMinimum: string = "";

    /**
     * @name Maximum
     * @description
     * The maximum value (int), length (string), file count (attachments) of the value for this control.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Maximum: number = 0;

    /**
     * @name ErrorMessageMaximum
     * @description
     * The error message to use when a control has exceeded the maximum.  {{MaximumLength}} and {{ActualLength}} placeholders are
     * available for use in the error message.
     * @servertype String
     * @type {string}
     */
    ErrorMessageMaximum: string = "";

    /**
     * @name Format
     * @description
     * Regular expression used to validate the format of the input value.
     * @servertype String
     * @type {string}
     */
    Format: string = "";

    /**
     * @name ErrorMessageInvalidFormat
     * @description
     * The error message to use when a control has an invalid format.  A {{FormatErrorMessage}} placeholder is supported for use
     * in the error message.
     * @servertype String
     * @type {string}
     */
    ErrorMessageInvalidFormat: string = "";

    /**
     * @name ErrorMessageOther
     * @description
     * The error message to use when a control has an other error.  A {{OtherErrorMessage}} placeholder is supported for use in
     * the error message.
     * @servertype String
     * @type {string}
     */
    ErrorMessageOther: string = "";

    /**
     * @name ControlOrder
     * @description
     * The order of the control within the group.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ControlOrder: number = 0;

    /**
     * @name ChangeEvent
     * @description
     * The event to fire when the input changes due to user interaction.
     * @servertype String
     * @type {string}
     */
    ChangeEvent: string = "";

    /**
     * @name ClickEvent
     * @description
     * The event to fire when the control is clicked.
     * @servertype String
     * @type {string}
     */
    ClickEvent: string = "";

    /**
     * @name CacheMinutes
     * @description
     * The optional number of minutes to cache form control information to minimize excessive server actions.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CacheMinutes: number = 0;

    /**
     * @name SecurityScope
     * @description
     * A security scope object that is used to determine if this control is included or not.  This dictates things like modules,
     * groups, roles, and permissions that are required.
     * @servertype IB.Data.Model.System.SecurityScope
     * @type {m.SecurityScope}
     */
    SecurityScope: m.SecurityScope = null;

    /**
     * @name Flags
     * @description
     * A list of flags for this control.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description
     * Properties related to this form control in JSON format.  JSON properties include:
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name FormControlGroupEditViewModel
 * @description
 * The Form Control Group table contains grouping information for controls that are part of a form.  This helps with presentation
 * and layout of complex forms.
 */
export class FormControlGroupEditViewModel {

    /**
     * @name FormControlGroupId
     * @description
     * The id of the form control group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormControlGroupId: number = null;

    /**
     * @name FormId
     * @description
     * The id of the form this group belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormId: number = null;

    /**
     * @name ParentFormControlGroupId
     * @description
     * The id of the form control group this group belongs to if it has a parent; otherwise, null.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentFormControlGroupId: number = null;

    /**
     * @name Description
     * @description
     * The description of this form control group.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name GroupOrder
     * @description
     * The order of the form control group.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    GroupOrder: number = 0;

    /**
     * @name Enabled
     * @description
     * When true the form group is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Label
     * @description
     * A label to use for this group when the presentation style supports labels.  This can also contain html markup.
     * @servertype String
     * @type {string}
     */
    Label: string = "";

    /**
     * @name Icon
     * @description
     * An icon to use for this group when the presentation style supports icons.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name HelpTitle
     * @description
     * Optional help title to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    HelpTitle: string = "";

    /**
     * @name HelpText
     * @description
     * Optional help text to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    HelpText: string = "";

    /**
     * @name GroupScope
     * @description
     * The group scope for this control group.  Note that child groups automatically are in the same scope as the parent group.
     *  Possible values include:
     * L = List (control group is for list layout)
     * A = Add (control group is for add form)
     * E = Edit (control group is for edit form)
     * B = Both (control group is for both add and edit forms)
     * @servertype String
     * @type {string}
     */
    GroupScope: string = "B";

    /**
     * @name GroupType
     * @description
     * The group type for this control group.  Possible values include:
     * G = General (General control group.)
     * S = Success (Control group displayed on success event.)
     * C = Cancel (Control group displayed on cancel event.)
     * A = All (Control group displayed for all types.  Useful for common elements like headers and footers.)
     * @servertype String
     * @type {string}
     */
    GroupType: string = "G";

    /**
     * @name VisibleInDesktopMode
     * @description
     * When true the form control group is visible in desktop mode.
     * @servertype Boolean
     * @type {boolean}
     */
    VisibleInDesktopMode: boolean = true;

    /**
     * @name VisibleInTabletMode
     * @description
     * When true the form control group is visible in tablet mode.
     * @servertype Boolean
     * @type {boolean}
     */
    VisibleInTabletMode: boolean = true;

    /**
     * @name VisibleInMobileMode
     * @description
     * When true the form control group is visible in mobile mode.
     * @servertype Boolean
     * @type {boolean}
     */
    VisibleInMobileMode: boolean = true;

    /**
     * @name PresentationStyle
     * @description
     * The presentation style to use for controls in this group.  When in wizard mode each group is presented as a page in the wizard.
     *  Possible values include:
     * S = TabSet (Each child group is a nav item as a tab.)
     * T = Nav (Tab or menu item.)
     * R = Row
     * C = Column
     * F = FieldSet
     * M = Menu Group (Each child group is a nav item as a menu item.)
     * A = Accordion (Each child group is a row as an accordion panel.)
     * B = Block (Useful for headers or footers.)
     * @servertype String
     * @type {string}
     */
    PresentationStyle: string = "B";

    /**
     * @name ColumnWidth
     * @description
     * If the presentation style for the group is column this is the column width.  If expressed as a percent it should end with
     * %.  If expressed in pixels it should end with px.  The default is to express it as a number of grid columns based on a 12
     * column grid.  This can be left blank in favor of automatic layout or more advanced width selection via group classes or styles.
     * @servertype String
     * @type {string}
     */
    ColumnWidth: string = "";

    /**
     * @name LabelClasses
     * @description
     * A space delimited list of classes used on the group label for presentation to the end user (e.g. CSS classes).
     * @servertype String
     * @type {string}
     */
    LabelClasses: string = "";

    /**
     * @name LabelStyles
     * @description
     * A semicolon delimited list of styles used on the group label for presentation to the end user (e.g. CSS styles).
     * @servertype String
     * @type {string}
     */
    LabelStyles: string = "";

    /**
     * @name GroupClasses
     * @description
     * A space delimited list of classes used on the group container for presentation to the end user (e.g. CSS classes).
     * @servertype String
     * @type {string}
     */
    GroupClasses: string = "";

    /**
     * @name GroupStyles
     * @description
     * A semicolon delimited list of styles used on the group container for presentation to the end user (e.g. CSS styles).
     * @servertype String
     * @type {string}
     */
    GroupStyles: string = "";

    /**
     * @name InheritLayout
     * @description
     * When true control layout elements are inherited from the form.
     * @servertype Boolean
     * @type {boolean}
     */
    InheritLayout: boolean = true;

    /**
     * @name LabelLayout
     * @description
     * The layout to use for control labels.  Possible values include:
     * D = Default
     * L = Left
     * T = Top
     * H = Hidden
     * @servertype String
     * @type {string}
     */
    LabelLayout: string = "D";

    /**
     * @name LabelBold
     * @description
     * When true control labels are bold.
     * @servertype Boolean
     * @type {boolean}
     */
    LabelBold: boolean = false;

    /**
     * @name ControlLayoutTight
     * @description
     * When true the control layout is tighter (i.e. less whitespace) than normal.
     * @servertype Boolean
     * @type {boolean}
     */
    ControlLayoutTight: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time.  When specified the form group is considered disabled prior to this time.  This can be used
     * to introduce changes at a certain date, handle forms that expire like event registrations, etc.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time.  When specified the form group is considered disabled after this time.  This can be used to
     * introduce changes at a certain date, handle forms that expire like event registrations, etc.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name DisplayWhenExpression
     * @description
     * When provided this represents an expression that is used to determine when the group should be displayed.  If this is a property
     * in ObjectName.PropertyName format the data is checked for this value and, when not empty, the form group is displayed.  If
     * this is a {{template}} then the data is evaluated using the template expression and, when the expression returns true the
     * form group is displayed.
     * @servertype String
     * @type {string}
     */
    DisplayWhenExpression: string = "";

    /**
     * @name LazyLoad
     * @description
     * When true and supported by the form control group the group contents will be lazy loaded when the group becomes visible.
     * @servertype Boolean
     * @type {boolean}
     */
    LazyLoad: boolean = true;

    /**
     * @name SecurityScope
     * @description
     * A security scope object that is used to determine if this control is included or not.  This dictates things like modules,
     * groups, roles, and permissions that are required.
     * @servertype IB.Data.Model.System.SecurityScope
     * @type {m.SecurityScope}
     */
    SecurityScope: m.SecurityScope = null;

    /**
     * @name Flags
     * @description
     * A list of flags for this form group.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description
     * Properties related to this form control group in JSON format.  JSON properties include:
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Groups
     * @description
     * List of s for the form control group.
     * @servertype FormControlGroupEditViewModel array
     * @type {FormControlGroupEditViewModel[]}
     */
    Groups: FormControlGroupEditViewModel[] = [];

    /**
     * @name Controls
     * @description
     * List of form controls for the form control group.
     * @servertype FormControlEditViewModel array
     * @type {FormControlEditViewModel[]}
     */
    Controls: FormControlEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name FormEditViewModel
 * @description
 * The Form table contains a list of forms.  A form is a collection of user interface controls used to present a view of information
 * from the database for viewing, adding, or editing data from the database.
 */
export class FormEditViewModel {

    /**
     * @name FormId
     * @description
     * The id of the form.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormId: number = null;

    /**
     * @name ExternalFormId
     * @description
     * An optional external form id for this form.
     * @servertype String
     * @type {string}
     */
    ExternalFormId: string = "";

    /**
     * @name SystemFormId
     * @description
     * When populated, this form represents a system form which is automatically used in place of default system forms.
     * @servertype String
     * @type {string}
     */
    SystemFormId: string = "";

    /**
     * @name Description
     * @description
     * The description of this form.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Tags
     * @description
     * A list of tags for this form.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Enabled
     * @description
     * When true the form is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name RoleId
     * @description
     * When specified this is the role id that is required for access to this form.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name TableNameReference
     * @description
     * If specified this references the table this form utilizes.
     * @servertype String
     * @type {string}
     */
    TableNameReference: string = "";

    /**
     * @name ApiNameReference
     * @description
     * If specified this references the api this form utilizes.
     * @servertype String
     * @type {string}
     */
    ApiNameReference: string = "";

    /**
     * @name Embedded
     * @description
     * When true the form is an embedded form and can only be selected in scenarios that call form embedded forms like various templates,
     * form controls, etc.  Embedded forms have the following differences:
     * * Embedded forms do not have separate list, add, edit modes.  Everything is treated as edit mode.
     * * Embedded forms do not have separate general, success, cancel, etc. types.  Everything is treated as general type.
     * * Embedded forms do not attempt to perform any explicit save or cancel actions event when save or cancel buttons are rendered
     * in the form.  Instead those buttons trigger only emit events that indicate the button has been clicked and it is the responsibility
     * of the embedded form host to perform the actions.
     * @servertype Boolean
     * @type {boolean}
     */
    Embedded: boolean = false;

    /**
     * @name UseBlankBasePage
     * @description
     * When true the form is displayed on a blank base page with no header, footer, menu, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    UseBlankBasePage: boolean = false;

    /**
     * @name FormClasses
     * @description
     * A space delimited list of classes used on the form container for presentation to the end user (e.g. CSS classes).
     * @servertype String
     * @type {string}
     */
    FormClasses: string = "";

    /**
     * @name FormStyles
     * @description
     * A semicolon delimited list of styles used on the form container for presentation to the end user (e.g. CSS styles).
     * @servertype String
     * @type {string}
     */
    FormStyles: string = "";

    /**
     * @name FormCss
     * @description
     * CSS custom to this form.
     * @servertype String
     * @type {string}
     */
    FormCss: string = "";

    /**
     * @name ListModeUseInLineEditing
     * @description
     * When true the list form supports in-line editing of values.
     * @servertype Boolean
     * @type {boolean}
     */
    ListModeUseInLineEditing: boolean = false;

    /**
     * @name ListModeDefaultSearchText
     * @description
     * This is the default search text to use when entering list mode.
     * @servertype String
     * @type {string}
     */
    ListModeDefaultSearchText: string = "";

    /**
     * @name ListModeDefaultFilter
     * @description
     * This is the default filter to use when entering list mode.
     * @servertype String
     * @type {string}
     */
    ListModeDefaultFilter: string = "";

    /**
     * @name AddModeRegistration
     * @description
     * The add mode of the form registration requirements.  Edit mode always requires a logged in user but add mode has the option
     * of running anonymously for things like easy sign up, product registration, etc. Possible values include:
     * L = Logged In.  Must be a logged in user.
     * R = Logged In with Registration Option.  Must login or register.
     * A = Anonymous
     * @servertype String
     * @type {string}
     */
    AddModeRegistration: string = "L";

    /**
     * @name AddMode
     * @description
     * The add mode of the form.  Possible values include:
     * F = Form
     * W = Wizard
     * D = Dialog
     * L = List
     * @servertype String
     * @type {string}
     */
    AddMode: string = "F";

    /**
     * @name AddModeWarnDataLoss
     * @description
     * When true, actions that would result in data loss produces a warning.
     * @servertype Boolean
     * @type {boolean}
     */
    AddModeWarnDataLoss: boolean = true;

    /**
     * @name AddModeShowErrors
     * @description
     * When true, form errors are displayed in add mode.
     * @servertype Boolean
     * @type {boolean}
     */
    AddModeShowErrors: boolean = true;

    /**
     * @name AddSaveButtonLabel
     * @description
     * The text to show on the save button for the add form.
     * @servertype String
     * @type {string}
     */
    AddSaveButtonLabel: string = "Save";

    /**
     * @name AddSaveButtonIcon
     * @description
     * The icon to show on the save button for the add form.
     * @servertype String
     * @type {string}
     */
    AddSaveButtonIcon: string = "";

    /**
     * @name AddSaveButtonIconAnimation
     * @description
     * The icon animation to use on the save button for the add form.
     * @servertype String
     * @type {string}
     */
    AddSaveButtonIconAnimation: string = "";

    /**
     * @name AddSaveButtonColor
     * @description
     * The color to use for the save button for the add form.
     * @servertype String
     * @type {string}
     */
    AddSaveButtonColor: string = "";

    /**
     * @name AddSaveButtonOutline
     * @description
     * When true, the save button is outline only.
     * @servertype Boolean
     * @type {boolean}
     */
    AddSaveButtonOutline: boolean = false;

    /**
     * @name AddSaveButtonHelpText
     * @description
     * Optional help text to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    AddSaveButtonHelpText: string = "";

    /**
     * @name AddSaveButtonDisableWhenPristine
     * @description
     * When true, the save button is disabled when the form is pristine.
     * @servertype Boolean
     * @type {boolean}
     */
    AddSaveButtonDisableWhenPristine: boolean = true;

    /**
     * @name AddSaveButtonDisableWhenInvalid
     * @description
     * When true, the save button is disabled when the form is invalid.
     * @servertype Boolean
     * @type {boolean}
     */
    AddSaveButtonDisableWhenInvalid: boolean = true;

    /**
     * @name AddSaveButtonDisableWhenWorking
     * @description
     * When true, the save button is disabled when the form is working.
     * @servertype Boolean
     * @type {boolean}
     */
    AddSaveButtonDisableWhenWorking: boolean = true;

    /**
     * @name AddSaveButtonLabelWhenWorking
     * @description
     * The text to show on the save button for the add form when the form is working.
     * @servertype String
     * @type {string}
     */
    AddSaveButtonLabelWhenWorking: string = "Please wait...";

    /**
     * @name AddSaveButtonIconWhenWorking
     * @description
     * The icon to show on the save button for the add form when the form is working.
     * @servertype String
     * @type {string}
     */
    AddSaveButtonIconWhenWorking: string = "spinner";

    /**
     * @name AddSaveButtonIconAnimationWhenWorking
     * @description
     * The icon animation to use on the save button for the add form when the form is working.
     * @servertype String
     * @type {string}
     */
    AddSaveButtonIconAnimationWhenWorking: string = "spin";

    /**
     * @name AddSaveAction
     * @description
     * When add save is completed this is the action to take.  Possible values include:
     * N = None
     * G = Display Success Group Type
     * L = Switch to List Mode
     * E = Switch to Edit Mode
     * U = Redirect to URL
     * M = Display Message
     * @servertype String
     * @type {string}
     */
    AddSaveAction: string = "N";

    /**
     * @name AddSaveInformation
     * @description
     * When save action is to display a message this is the message.  When set to redirect to url this is the url.
     * @servertype String
     * @type {string}
     */
    AddSaveInformation: string = "";

    /**
     * @name AddCancelButtonLabel
     * @description
     * The text to show on the cancel button for the add form.
     * @servertype String
     * @type {string}
     */
    AddCancelButtonLabel: string = "";

    /**
     * @name AddCancelButtonIcon
     * @description
     * The icon to show on the cancel button for the add form.
     * @servertype String
     * @type {string}
     */
    AddCancelButtonIcon: string = "";

    /**
     * @name AddCancelButtonColor
     * @description
     * The color to use for the cancel button for the add form.
     * @servertype String
     * @type {string}
     */
    AddCancelButtonColor: string = "";

    /**
     * @name AddCancelButtonOutline
     * @description
     * When true, the cancel button is outline only.
     * @servertype Boolean
     * @type {boolean}
     */
    AddCancelButtonOutline: boolean = false;

    /**
     * @name AddCancelButtonHelpText
     * @description
     * Optional help text to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    AddCancelButtonHelpText: string = "";

    /**
     * @name AddCancelButtonDisableWhenWorking
     * @description
     * When true, the cancel button is disabled when the form is working.
     * @servertype Boolean
     * @type {boolean}
     */
    AddCancelButtonDisableWhenWorking: boolean = true;

    /**
     * @name AddCancelAction
     * @description
     * When add is cancelled this is the action to take.  Possible values include:
     * N = None
     * C = Clear Form
     * G = Display Cancel Group Type
     * L = Switch to List Mode
     * U = Redirect to URL
     * M = Display Message
     * @servertype String
     * @type {string}
     */
    AddCancelAction: string = "N";

    /**
     * @name AddCancelInformation
     * @description
     * When cancel action is to display a message this is the message.  When set to redirect to url this is the url.
     * @servertype String
     * @type {string}
     */
    AddCancelInformation: string = "";

    /**
     * @name EditMode
     * @description
     * The edit mode of the form.  Possible values include:
     * F = Form
     * W = Wizard
     * D = Dialog
     * L = List
     * @servertype String
     * @type {string}
     */
    EditMode: string = "F";

    /**
     * @name EditModeWarnDataLoss
     * @description
     * When true, actions that would result in data loss produces a warning.
     * @servertype Boolean
     * @type {boolean}
     */
    EditModeWarnDataLoss: boolean = true;

    /**
     * @name EditModeShowErrors
     * @description
     * When true, form errors are displayed in edit mode.
     * @servertype Boolean
     * @type {boolean}
     */
    EditModeShowErrors: boolean = true;

    /**
     * @name EditSaveButtonLabel
     * @description
     * The text to show on the save button for the edit form.
     * @servertype String
     * @type {string}
     */
    EditSaveButtonLabel: string = "Save";

    /**
     * @name EditSaveButtonIcon
     * @description
     * The icon to show on the save button for the edit form.
     * @servertype String
     * @type {string}
     */
    EditSaveButtonIcon: string = "";

    /**
     * @name EditSaveButtonIconAnimation
     * @description
     * The icon animation to use on the save button for the edit form.
     * @servertype String
     * @type {string}
     */
    EditSaveButtonIconAnimation: string = "";

    /**
     * @name EditSaveButtonColor
     * @description
     * The color to use for the save button for the edit form.
     * @servertype String
     * @type {string}
     */
    EditSaveButtonColor: string = "";

    /**
     * @name EditSaveButtonOutline
     * @description
     * When true, the save button is outline only.
     * @servertype Boolean
     * @type {boolean}
     */
    EditSaveButtonOutline: boolean = false;

    /**
     * @name EditSaveButtonHelpText
     * @description
     * Optional help text to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    EditSaveButtonHelpText: string = "";

    /**
     * @name EditSaveButtonDisableWhenPristine
     * @description
     * When true, the save button is disabled when the form is pristine.
     * @servertype Boolean
     * @type {boolean}
     */
    EditSaveButtonDisableWhenPristine: boolean = true;

    /**
     * @name EditSaveButtonDisableWhenInvalid
     * @description
     * When true, the save button is disabled when the form is invalid.
     * @servertype Boolean
     * @type {boolean}
     */
    EditSaveButtonDisableWhenInvalid: boolean = true;

    /**
     * @name EditSaveButtonDisableWhenWorking
     * @description
     * When true, the save button is disabled when the form is working.
     * @servertype Boolean
     * @type {boolean}
     */
    EditSaveButtonDisableWhenWorking: boolean = true;

    /**
     * @name EditSaveButtonLabelWhenWorking
     * @description
     * The text to show on the save button for the edit form when the form is working.
     * @servertype String
     * @type {string}
     */
    EditSaveButtonLabelWhenWorking: string = "Please wait...";

    /**
     * @name EditSaveButtonIconWhenWorking
     * @description
     * The icon to show on the save button for the edit form when the form is working.
     * @servertype String
     * @type {string}
     */
    EditSaveButtonIconWhenWorking: string = "spinner";

    /**
     * @name EditSaveButtonIconAnimationWhenWorking
     * @description
     * The icon animation to use on the save button for the edit form when the form is working.
     * @servertype String
     * @type {string}
     */
    EditSaveButtonIconAnimationWhenWorking: string = "spin";

    /**
     * @name EditSaveAction
     * @description
     * When edit save is completed this is the action to take.  Possible values include:
     * N = None
     * G = Display Success Group Type
     * L = Switch to List Mode
     * U = Redirect to URL
     * M = Display Message
     * @servertype String
     * @type {string}
     */
    EditSaveAction: string = "N";

    /**
     * @name EditSaveInformation
     * @description
     * When save action is to display a message this is the message.  When set to redirect to url this is the url.
     * @servertype String
     * @type {string}
     */
    EditSaveInformation: string = "";

    /**
     * @name EditCancelButtonLabel
     * @description
     * The text to show on the cancel button for the edit form.
     * @servertype String
     * @type {string}
     */
    EditCancelButtonLabel: string = "";

    /**
     * @name EditCancelButtonIcon
     * @description
     * The icon to show on the cancel button for the edit form.
     * @servertype String
     * @type {string}
     */
    EditCancelButtonIcon: string = "";

    /**
     * @name EditCancelButtonColor
     * @description
     * The color to use for the cancel button for the edit form.
     * @servertype String
     * @type {string}
     */
    EditCancelButtonColor: string = "";

    /**
     * @name EditCancelButtonOutline
     * @description
     * When true, the cancel button is outline only.
     * @servertype Boolean
     * @type {boolean}
     */
    EditCancelButtonOutline: boolean = false;

    /**
     * @name EditCancelButtonHelpText
     * @description
     * Optional help text to present to the end user via tooltip or other mechanism.
     * @servertype String
     * @type {string}
     */
    EditCancelButtonHelpText: string = "";

    /**
     * @name EditCancelButtonDisableWhenWorking
     * @description
     * When true, the cancel button is disabled when the form is working.
     * @servertype Boolean
     * @type {boolean}
     */
    EditCancelButtonDisableWhenWorking: boolean = true;

    /**
     * @name EditCancelAction
     * @description
     * When edit is cancelled this is the action to take.  Possible values include:
     * N = None
     * C = Clear Form
     * G = Display Cancel Group Type
     * L = Switch to List Mode
     * U = Redirect to URL
     * M = Display Message
     * @servertype String
     * @type {string}
     */
    EditCancelAction: string = "N";

    /**
     * @name EditCancelInformation
     * @description
     * When cancel action is to display a message this is the message.  When set to redirect to url this is the url.
     * @servertype String
     * @type {string}
     */
    EditCancelInformation: string = "";

    /**
     * @name LabelLayout
     * @description
     * The layout to use for control labels.  Possible values include:
     * D = Default
     * L = Left
     * T = Top
     * H = Hidden
     * @servertype String
     * @type {string}
     */
    LabelLayout: string = "D";

    /**
     * @name LabelBold
     * @description
     * When true control labels are bold.
     * @servertype Boolean
     * @type {boolean}
     */
    LabelBold: boolean = false;

    /**
     * @name ControlLayoutTight
     * @description
     * When true the control layout is tighter (i.e. less whitespace) than normal.
     * @servertype Boolean
     * @type {boolean}
     */
    ControlLayoutTight: boolean = false;

    /**
     * @name FormErrorsHandledInternally
     * @description
     * When true the form rendering does not render any form errors as they will be handled by form controls.
     * @servertype Boolean
     * @type {boolean}
     */
    FormErrorsHandledInternally: boolean = false;

    /**
     * @name ErrorMessageRequiredSingle
     * @description
     * The error message to display with submit button when a single required control is in error state.  A {{ControlNames}} placeholder
     * is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either a single
     * control name or a comma separate list of control names.
     * @servertype String
     * @type {string}
     */
    ErrorMessageRequiredSingle: string = "";

    /**
     * @name ErrorMessageRequiredMultiple
     * @description
     * The error message to display with submit button when multiple required controls are in error state.  A {{ControlNames}} placeholder
     * is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either a single
     * control name or a comma separate list of control names.
     * @servertype String
     * @type {string}
     */
    ErrorMessageRequiredMultiple: string = "";

    /**
     * @name ErrorMessageMinimumLengthSingle
     * @description
     * The error message to display with submit button when a single minimum length control is in error state.  A {{ControlNames}}
     * placeholder is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either
     * a single control name or a comma separate list of control names.
     * @servertype String
     * @type {string}
     */
    ErrorMessageMinimumLengthSingle: string = "";

    /**
     * @name ErrorMessageMinimumLengthMultiple
     * @description
     * The error message to display with submit button when multiple minimum length controls are in error state.  A {{ControlNames}}
     * placeholder is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either
     * a single control name or a comma separate list of control names.
     * @servertype String
     * @type {string}
     */
    ErrorMessageMinimumLengthMultiple: string = "";

    /**
     * @name ErrorMessageMaximumLengthSingle
     * @description
     * The error message to display with submit button when a single maximum length control is in error state.  A {{ControlNames}}
     * placeholder is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either
     * a single control name or a comma separate list of control names.
     * @servertype String
     * @type {string}
     */
    ErrorMessageMaximumLengthSingle: string = "";

    /**
     * @name ErrorMessageMaximumLengthMultiple
     * @description
     * The error message to display with submit button when multiple maximum length controls are in error state.  A {{ControlNames}}
     * placeholder is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either
     * a single control name or a comma separate list of control names.
     * @servertype String
     * @type {string}
     */
    ErrorMessageMaximumLengthMultiple: string = "";

    /**
     * @name ErrorMessageInvalidFormatSingle
     * @description
     * The error message to display with submit button when a single control has an invalid format.  A {{ControlNames}} placeholder
     * is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either a single
     * control name or a comma separate list of control names.
     * @servertype String
     * @type {string}
     */
    ErrorMessageInvalidFormatSingle: string = "";

    /**
     * @name ErrorMessageInvalidFormatMultiple
     * @description
     * The error message to display with submit button when multiple controls have an invalid format.  A {{ControlNames}} placeholder
     * is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either a single
     * control name or a comma separate list of control names.
     * @servertype String
     * @type {string}
     */
    ErrorMessageInvalidFormatMultiple: string = "";

    /**
     * @name ErrorMessageOtherSingle
     * @description
     * The error message to display with submit button when a single control has an other error.  A {{ControlNames}} placeholder
     * is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either a single
     * control name or a comma separate list of control names.  A {{OtherErrorMessage}} placeholder may provide some insight into
     * the error.
     * @servertype String
     * @type {string}
     */
    ErrorMessageOtherSingle: string = "";

    /**
     * @name ErrorMessageOtherMultiple
     * @description
     * The error message to display with submit button when multiple controls have an other error.  A {{ControlNames}} placeholder
     * is available for placement of the control(s) in this error state.  The {{ControlNames}} placeholder will be either a single
     * control name or a comma separate list of control names.  A {{OtherErrorMessage}} placeholder may provide some insight into
     * the error.
     * @servertype String
     * @type {string}
     */
    ErrorMessageOtherMultiple: string = "";

    /**
     * @name ScriptList
     * @description
     * The script to include for list mode.
     * @servertype String
     * @type {string}
     */
    ScriptList: string = "";

    /**
     * @name ScriptAdd
     * @description
     * The script to include for add mode.
     * @servertype String
     * @type {string}
     */
    ScriptAdd: string = "";

    /**
     * @name ScriptEdit
     * @description
     * The script to include for edit mode.
     * @servertype String
     * @type {string}
     */
    ScriptEdit: string = "";

    /**
     * @name EventListSearchButtonClick
     * @description
     * The event to fire when the list/grid search button is checked.  If not specified no search option is provided.
     * @servertype String
     * @type {string}
     */
    EventListSearchButtonClick: string = "";

    /**
     * @name EventListPageChange
     * @description
     * The event to fire when the list/grid page is changed.  If not specified no paging is provided.
     * @servertype String
     * @type {string}
     */
    EventListPageChange: string = "";

    /**
     * @name EventListPageSizeChange
     * @description
     * The event to fire when the list/grid page size is changed.  If not specified no page size option is provided.
     * @servertype String
     * @type {string}
     */
    EventListPageSizeChange: string = "";

    /**
     * @name EventListLoad
     * @description
     * The event to fire when the list/grid is loaded.
     * @servertype String
     * @type {string}
     */
    EventListLoad: string = "";

    /**
     * @name EventListRowClick
     * @description
     * The event to fire when a row in the list/grid is clicked.
     * @servertype String
     * @type {string}
     */
    EventListRowClick: string = "";

    /**
     * @name EventListAdd
     * @description
     * The event to fire when add option is selected in the list/grid.
     * @servertype String
     * @type {string}
     */
    EventListAdd: string = "";

    /**
     * @name EventListEdit
     * @description
     * The event to fire when edit option is selected in the list/grid.
     * @servertype String
     * @type {string}
     */
    EventListEdit: string = "";

    /**
     * @name EventListDelete
     * @description
     * The event to fire when delete option is selected in the list/grid.
     * @servertype String
     * @type {string}
     */
    EventListDelete: string = "";

    /**
     * @name EventAddLoad
     * @description
     * The event to fire when the add form is loaded.
     * @servertype String
     * @type {string}
     */
    EventAddLoad: string = "";

    /**
     * @name EventAddSave
     * @description
     * The event to fire when the add form is saved.
     * @servertype String
     * @type {string}
     */
    EventAddSave: string = "";

    /**
     * @name EventEditLoad
     * @description
     * The event to fire when the edit form is loaded.
     * @servertype String
     * @type {string}
     */
    EventEditLoad: string = "";

    /**
     * @name EventEditSave
     * @description
     * The event to fire when the edit form is saved.
     * @servertype String
     * @type {string}
     */
    EventEditSave: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags for this form.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description
     * Properties related to this form in JSON format.  JSON properties include:
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Groups
     * @description
     * List of control groups for the form.
     * @servertype FormControlGroupEditViewModel array
     * @type {FormControlGroupEditViewModel[]}
     */
    Groups: FormControlGroupEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Help Link
 * @description
 * This contains information about a help link for a given context.
 */
export class HelpLinkEditViewModel {

    /**
     * @name HelpLinkId
     * @description
     * The Help Link Id uniquely identifies this help link.
     * @servertype Int64
     * @type {number}
     */
    HelpLinkId: number = 0;

    /**
     * @name Description
     * @description
     * The description for this help link.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * True indicates the help links are enabled.  Disabled links allow building of links that are work in progress.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Context
     * @description
     * The context this help link is valid for.  This is the primary context used to determine which help links are valid. The context
     * can be further refined by Brands, Tags, StartingCoreApiVersion, EndingCoreApiVersion, StartingAppVersion, and EndingAppVersion.
     * @servertype String
     * @type {string}
     */
    Context: string = "";

    /**
     * @name Brands
     * @description
     * List of brands this help link is valid for.  These are string representations of BrandId.  If the list is empty it is considered
     * valid for any brand.
     * @servertype string array
     * @type {string[]}
     */
    Brands: string[] = [];

    /**
     * @name Tags
     * @description
     * List of tags this help link is valid for.  If the list is empty it is considered valid for any tag and if no tag is specified
     * it is considered valid regardless of the contents of this list but if a tag is specified for context when looking for help
     * links and the list is not empty then the tag must be in the list for it to be a match.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name StartingCoreApiVersion
     * @description
     * The staring core api version this help link is valid for.  This can be used to limit the scope for the help link.
     * @servertype String
     * @type {string}
     */
    StartingCoreApiVersion: string = "";

    /**
     * @name EndingCoreApiVersion
     * @description
     * The ending cope api version this help link is valid for.  This can be used to limit the scope for the help link.
     * @servertype String
     * @type {string}
     */
    EndingCoreApiVersion: string = "";

    /**
     * @name StartingAppVersion
     * @description
     * The staring app version this help link is valid for.  This can be used to limit the scope for the help link.
     * @servertype String
     * @type {string}
     */
    StartingAppVersion: string = "";

    /**
     * @name EndingAppVersion
     * @description
     * The ending app version this help link is valid for.  This can be used to limit the scope for the help link.
     * @servertype String
     * @type {string}
     */
    EndingAppVersion: string = "";

    /**
     * @name EmbeddedContexts
     * @description
     * Embedded contexts allow help links for other contexts to be included with results for the current context.  This is useful
     * for scenarios where in addition to help links on a given topic we want to include links related to an overview of the area,
     * etc.  Using an embedded context has a benefit over duplicate help links because if the help links for an embedded context
     * are changed the change will automatically be picked up where the context has been embedded.  Note that embedded contexts
     * referenced must pass all context filters (e.g. brand, tag, version) in order to be included and that embedded contexts will
     * not result in duplicate links being included.
     * @servertype string array
     * @type {string[]}
     */
    EmbeddedContexts: string[] = [];

    /**
     * @name IncludeOnScreenHelpTips
     * @description
     * True indicates help links should include a link to show on-screen help tips.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeOnScreenHelpTips: boolean = false;

    /**
     * @name IncludeHelpIndex
     * @description
     * True indicates help links should include a link to the help index.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeHelpIndex: boolean = false;

    /**
     * @name IncludeSystemHelpLinks
     * @description
     * True indicates help links should include any other system wide help links configured.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeSystemHelpLinks: boolean = false;

    /**
     * @name IncludeContactSupport
     * @description
     * True indicates help links should include a link to contact support.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeContactSupport: boolean = false;

    /**
     * @name Links
     * @description
     * An ordered list of help links for this context.
     * @servertype HelpLinkItemEditViewModel array
     * @type {HelpLinkItemEditViewModel[]}
     */
    Links: HelpLinkItemEditViewModel[] = [];

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Properties
     * @description
     * Other properties related to this item.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Help Link Item
 * @description
 * This contains information about a help link item.
 */
export class HelpLinkItemEditViewModel {

    /**
     * @name HelpLinkItemId
     * @description
     * A unique id for this help link item.  It only needs to be unique in the context of the help link object the item belongs
     * to.
     * @servertype Int64
     * @type {number}
     */
    HelpLinkItemId: number = 0;

    /**
     * @name Enabled
     * @description
     * True indicates the help links are enabled.  Disabled links allow building of links that are work in progress.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Description
     * @description
     * Description for the help link topic or url.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Icon
     * @description
     * The icon to use for the help link.  Leave blank for the default icon.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name HelpLinkItemType
     * @description
     * The help link item type.
     * @servertype String
     * @type {string}
     */
    HelpLinkItemType: string = "";

    /**
     * @name Id
     * @description
     * The id for the help link topic.
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Url
     * @description
     * A full url to use if using a custom help link instead of id and description for a topic.
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name Message
     * @description
     * When the help link type is message this is the message to show.
     * @servertype IB.Data.Model.v5.HelpLinkItemMessageEditViewModel
     * @type {HelpLinkItemMessageEditViewModel}
     */
    Message: HelpLinkItemMessageEditViewModel = null;

    /**
     * @name Tour
     * @description
     * When the help link type is tour this is the tour to run.
     * @servertype IB.Data.Model.v5.HelpLinkItemTourEditViewModel
     * @type {HelpLinkItemTourEditViewModel}
     */
    Tour: HelpLinkItemTourEditViewModel = null;

    /**
     * @name BadgeText
     * @description
     * Badges can appear after a help link item text to draw attention to the help link.  This can be used for things like new help
     * links, special or high priority help links, etc.  If a badge is desired this is the badge text.
     * @servertype String
     * @type {string}
     */
    BadgeText: string = "";

    /**
     * @name BadgeIcon
     * @description
     * If an icon is desired to appear before the badge text this is the icon.
     * @servertype String
     * @type {string}
     */
    BadgeIcon: string = "";

    /**
     * @name BadgeColor
     * @description
     * The color to use for the badge.  Leave blank for the default color.
     * @servertype String
     * @type {string}
     */
    BadgeColor: string = "";

    /**
     * @name BadgeUntil
     * @description
     * The date after which the badge will no longer appear.  This is helpful for new menu items that should be highlighted as such
     * until a specific date.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BadgeUntil: Date = null;

    /**
     * @name HelpMenuSpotlightUntil
     * @description
     * Help menu items appear under a help menu which can cause them to not get noticed.  If this help menu should draw attention
     * to itself in addition to specifying a badge which is not shown until the help menu is shown setting a help menu spotlight
     * until date will cause the help menu itself to get some spotlight icon or action to draw attention to it thereby increasing
     * the chances that the menu will be displayed and this help item will be noticed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    HelpMenuSpotlightUntil: Date = null;

    /**
     * @name AutoOpen
     * @description
     * Flag if the help resource should auto open.  Note that only messages and tours can auto open.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoOpen: boolean = false;

    /**
     * @name AutoOpenWhenUrlContains
     * @description
     * A url fragment which if detected in the url should result in the help resource doing an auto open.  Note that only messages
     * and tours can auto open.
     * @servertype String
     * @type {string}
     */
    AutoOpenWhenUrlContains: string = "";

    /**
     * @name AutoOpenCanBeDisabled
     * @description
     * When auto open is triggered this indicates if the user should be able to disable future auto open for this help link item.
     *  This should normally be set to true which will provide a check box the user can check to indicate they don't want to see
     * this again automatically.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoOpenCanBeDisabled: boolean = false;

    /**
     * @name Language
     * @description
     * The language for this help link item.  This can be used to provide language specific help links.  For help links that should
     * be included regardless of the requested language leave this value empty.
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Properties
     * @description
     * Other properties related to this item.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

}



/**
 * @name Help Link Item Message
 * @description
 * This contains information about a message for help link items that are a message.
 */
export class HelpLinkItemMessageEditViewModel {

    /**
     * @name Title
     * @description
     * The message title.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name TitleIcon
     * @description
     * The message title icon.  Leave blank for the default icon.
     * @servertype String
     * @type {string}
     */
    TitleIcon: string = "";

    /**
     * @name TitleColor
     * @description
     * The message title color.  Leave blank for the default color.
     * @servertype String
     * @type {string}
     */
    TitleColor: string = "";

    /**
     * @name Text
     * @description
     * The message text.  This can include markup like html.
     * @servertype String
     * @type {string}
     */
    Text: string = "";

    /**
     * @name TextType
     * @description
     * The text type.
     * @servertype String
     * @type {string}
     */
    TextType: string = "";

    /**
     * @name WindowSize
     * @description
     * The help link message window size.
     * @servertype String
     * @type {string}
     */
    WindowSize: string = "";

    /**
     * @name Movable
     * @description
     * When true the window showing the message can be moved.
     * @servertype Boolean
     * @type {boolean}
     */
    Movable: boolean = false;

    /**
     * @name Properties
     * @description
     * Other properties related to this item.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

}



/**
 * @name Help Link Item Tour
 * @description
 * This contains information about a tour for help link items that are a tour.
 */
export class HelpLinkItemTourEditViewModel {

    /**
     * @name Title
     * @description
     * The title for this tour.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @description
     * The description for this tour.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Steps
     * @description
     * The steps that compose this tour.
     * @servertype HelpLinkItemTourStepEditViewModel array
     * @type {HelpLinkItemTourStepEditViewModel[]}
     */
    Steps: HelpLinkItemTourStepEditViewModel[] = [];

    /**
     * @name Properties
     * @description
     * Other properties related to this item.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

}



/**
 * @name Help Link Item Tour Step
 * @description
 * This contains information about a single tour step.
 */
export class HelpLinkItemTourStepEditViewModel {

    /**
     * @name SequenceNumber
     * @description
     * The sequence number for this step.
     * @servertype Int32
     * @type {number}
     */
    SequenceNumber: number = 0;

    /**
     * @name Selector
     * @description
     * The CSS selector used to find the target element for this step.
     * @servertype String
     * @type {string}
     */
    Selector: string = "";

    /**
     * @name Title
     * @description
     * The title for this tour step.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Text
     * @description
     * The text for this tour step.
     * @servertype String
     * @type {string}
     */
    Text: string = "";

    /**
     * @name Image
     * @description
     * A link to an optional image to use for a tour step illustration.
     * @servertype String
     * @type {string}
     */
    Image: string = "";

    /**
     * @name TextType
     * @description
     * The text type.
     * @servertype String
     * @type {string}
     */
    TextType: string = "";

    /**
     * @name AllowTargetClickEvent
     * @description
     * When true the tour will allow the target element to have click events.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowTargetClickEvent: boolean = false;

    /**
     * @name OnStepClickTarget
     * @description
     * When true the step starting will trigger a click event on the target element.
     * @servertype Boolean
     * @type {boolean}
     */
    OnStepClickTarget: boolean = false;

    /**
     * @name OnTargetClickMoveToNextStep
     * @description
     * When true a click on the target element will move the tour to the next step.
     * @servertype Boolean
     * @type {boolean}
     */
    OnTargetClickMoveToNextStep: boolean = false;

    /**
     * @name Actions
     * @description
     * Actions related to this tour step.  This is a JSON string in the format expected by the tour engine.
     * @servertype System.Object
     * @type {any}
     */
    Actions: any = {};

    /**
     * @name Properties
     * @description
     * Other properties related to this item.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

}



/**
 * @name Search Configuration
 * @description
 * Information about a search configuration.
 */
export class SearchConfigurationViewModel {

    /**
     * @name SearchConfigurationId
     * @description
     * A unique id for the search configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SearchConfigurationId: number = 0;

    /**
     * @name ExternalSearchConfigurationId
     * @description
     * An optional external id for the search configuration.
     * @servertype String
     * @type {string}
     */
    ExternalSearchConfigurationId: string = "";

    /**
     * @name Description
     * @description
     * The description of this search configuration.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LinkSearchToAppTable
     * @description
     * If the search configuration should be linked to an app table this is the name of the table where it will be used.
     * @servertype String
     * @type {string}
     */
    LinkSearchToAppTable: string = "";

    /**
     * @name LinkSearchToAppTableStyle
     * @description
     * If the search configuration should be linked to an app table this is the style to use for that link.  Options are:
     * always-display = Always display the search form on the app table.
     * only-display = Only display the search form on the app table.  Other search controls like free form text, filter buttons,
     * etc. are hidden.
     * filter-option = Display the search form as an option on the filter button.
     * search-button = Display the search form via a search button.
     * @servertype String
     * @type {string}
     */
    LinkSearchToAppTableStyle: string = "";

    /**
     * @name LinkSearchToAppTableDisablePrefetch
     * @description
     * If the search configuration should be linked to an app table this flag can be used to disable prefetch where one screen worth
     * of rows are automatically fetched without a search being submitted.
     * @servertype Boolean
     * @type {boolean}
     */
    LinkSearchToAppTableDisablePrefetch: boolean = false;

    /**
     * @name LinkSearchToAppGlobalSearch
     * @description
     * If the search configuration should be linked to the app global search feature this flag should be set to true.
     * @servertype Boolean
     * @type {boolean}
     */
    LinkSearchToAppGlobalSearch: boolean = false;

    /**
     * @name LinkSearchToAppDisplayOrder
     * @description
     * The display order to use when multiple search configurations are configured to be linked to an app table or app global search.
     * @servertype Int32
     * @type {number}
     */
    LinkSearchToAppDisplayOrder: number = 0;

    /**
     * @name SearchFormId
     * @description
     * The form id of the form used to collect search information.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SearchFormId: number = 0;

    /**
     * @name SearchFilterBuilderTriggerAssetId
     * @description
     * An asset id to a trigger used to translate the search form contents into a filter string used for the search.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SearchFilterBuilderTriggerAssetId: number = 0;

    /**
     * @name SearchFilterBuilderTriggerAssetTitle
     * @description
     * The title of the asset to a trigger used to translate the search form contents into a filter string used for the search.
     * @servertype String
     * @type {string}
     */
    SearchFilterBuilderTriggerAssetTitle: string = "";

    /**
     * @name SearchFilterBuilderScript
     * @description
     * A script that accepts a parameter of search form contents object and returns a string used as the search filter based on
     * the search form contents.
     * @servertype IB.Data.Model.v5.ScriptViewModel
     * @type {m5core.ScriptViewModel}
     */
    SearchFilterBuilderScript: m5core.ScriptViewModel = null;

    /**
     * @name SearchQueryId
     * @description
     * If the search is being done using a query this is the id of the query to use for executing the search.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SearchQueryId: number = 0;

    /**
     * @name SearchQueryApiProperties
     * @description
     * If the search is being done using a list api endpoint this is the name of the api properties to use for the search.
     * @servertype String
     * @type {string}
     */
    SearchQueryApiProperties: string = "";

    /**
     * @name SearchQueryColumns
     * @description
     * If the search is using a free-form query this is the columns to include expressed as a comma delimited string like a sql
     * select columns clause.
     * @servertype String
     * @type {string}
     */
    SearchQueryColumns: string = "";

    /**
     * @name SearchQueryEntities
     * @description
     * If the search is using a free-form query this is the entities to query expressed like a sql from clause.
     * @servertype String
     * @type {string}
     */
    SearchQueryEntities: string = "";

    /**
     * @name SearchQueryFilter
     * @description
     * If the search is using a free-form query this is an optional hard coded filter expressed like a sql where clause.  This will
     * be combined with whatever filter is created from the search form contents.
     * @servertype String
     * @type {string}
     */
    SearchQueryFilter: string = "";

    /**
     * @name SearchQuerySort
     * @description
     * If the search is using a free-form query this is the sort for the query results like a sql order by clause with - prefix
     * as optional alternate to DESC suffix for descending columns.
     * @servertype String
     * @type {string}
     */
    SearchQuerySort: string = "";

    /**
     * @name SearchResultTitle
     * @description
     * A title to show above search results.
     * @servertype String
     * @type {string}
     */
    SearchResultTitle: string = "";

    /**
     * @name SearchResultIncludeNewSearchOption
     * @description
     * When true the search results will include a button for performing a new search.
     * @servertype Boolean
     * @type {boolean}
     */
    SearchResultIncludeNewSearchOption: boolean = false;

    /**
     * @name SearchResultSessionSeconds
     * @description
     * The number of seconds to keep the search session active.  Use 0 to not use search sessions.  When this value is set and the
     * search page is navigated to before this number of seconds has elapsed since the last search it is considered to be the same
     * search session and the search results are restored instead of the search form being displayed.
     * @servertype Int32
     * @type {number}
     */
    SearchResultSessionSeconds: number = 0;

    /**
     * @name SearchResultColumns
     * @description
     * Search result columns used to determine how to render the search results.
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    SearchResultColumns: m.PropertyMetaDataViewModel[] = [];

    /**
     * @name SearchRowClickUrl
     * @description
     * When a row is clicked in the search results this is the url to redirect to.  It can contain {{placeholders}} with the name
     * of row properties that can be injected in the url.
     * @servertype String
     * @type {string}
     */
    SearchRowClickUrl: string = "";

    /**
     * @name SearchRowClickNewPage
     * @description
     * When true a row click in the search results will open the url in a new page.
     * @servertype Boolean
     * @type {boolean}
     */
    SearchRowClickNewPage: boolean = false;

    /**
     * @name ApprovalPending
     * @description
     * Indicates the object is ready for review and approval.  This may also be triggered by object changes.
     * @servertype Boolean
     * @type {boolean}
     */
    ApprovalPending: boolean = false;

    /**
     * @name ApprovedDateTime
     * @description
     * The date and time the object was approved.  Depending on the scenario, objects that are not approved may not be allowed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @description
     * The id of the contact who approved or reviewed the object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @description
     * The name of the contact who approved or reviewed the object.
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovedFingerprint
     * @description
     * A fingerprint of the object when it was approved.  Subsequent changes to the object will result in a fingerprint mismatch
     * and, depending on the scenario, objects with a different fingerprint may not be allowed.
     * @servertype String
     * @type {string}
     */
    ApprovedFingerprint: string = "";

    /**
     * @name ApprovalComments
     * @description
     * Comments left by the person who reviewed and approved or declined to approve.
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

    /**
     * @name Comments
     * @description
     * Comments regarding this search configuration.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Search Configuration
 * @description
 * Information about a search configuration.
 */
export class TableConfigurationViewModel {

    /**
     * @name TableConfigurationId
     * @description
     * The Internal id for the table configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TableConfigurationId: number = 0;

    /**
     * @name Description
     * @description
     * The description of this table configuration.  This is the table id that the client uses to reference the configuration.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the config is enabled.  When false the config is ignored.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Title
     * @description
     * Title to display with the table.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name RowsPerPage
     * @description
     * Default number of rows per page to show.
     * @servertype Int32
     * @type {number}
     */
    RowsPerPage: number = 0;

    /**
     * @name RowsPerPageOptions
     * @description
     * List of options for number of rows per page.
     * @servertype int array
     * @type {number[]}
     */
    RowsPerPageOptions: number[] = [];

    /**
     * @name RowsPerPageSelector
     * @description
     * When true the selector for the number of rows per page is displayed.
     * @servertype Boolean
     * @type {boolean}
     */
    RowsPerPageSelector: boolean = false;

    /**
     * @name RowCountMessage
     * @description
     * When true the row count message is displayed.
     * @servertype Boolean
     * @type {boolean}
     */
    RowCountMessage: boolean = false;

    /**
     * @name Paging
     * @description
     * When true a paging control is displayed to allow page navigation.
     * @servertype Boolean
     * @type {boolean}
     */
    Paging: boolean = false;

    /**
     * @name GlobalSearch
     * @description
     * When true a global search control is displayed for keyword searching.
     * @servertype Boolean
     * @type {boolean}
     */
    GlobalSearch: boolean = false;

    /**
     * @name FilterAllowSaved
     * @description
     * When true saved filters are allowed.
     * @servertype Boolean
     * @type {boolean}
     */
    FilterAllowSaved: boolean = false;

    /**
     * @name FilterAllowBuilder
     * @description
     * When true a filter builder is allowed.
     * @servertype Boolean
     * @type {boolean}
     */
    FilterAllowBuilder: boolean = false;

    /**
     * @name FilterAllowAdvanced
     * @description
     * When true advanced filters are allowed.
     * @servertype Boolean
     * @type {boolean}
     */
    FilterAllowAdvanced: boolean = false;

    /**
     * @name DateRange
     * @description
     * The default date range to use for the table if the table uses a date range picker.
     * @servertype String
     * @type {string}
     */
    DateRange: string = "";

    /**
     * @name SortMode
     * @description
     * Sort mode: none, single, multiple
     * @servertype String
     * @type {string}
     */
    SortMode: string = "";

    /**
     * @name Sort
     * @description
     * The sort order as a CSV where - indicates descending order.
     * @servertype String
     * @type {string}
     */
    Sort: string = "";

    /**
     * @name Expand
     * @description
     * Any hard coded expand option that should be applied when loading data from server to get full objects instead of list, include
     * children, etc.
     * @servertype String
     * @type {string}
     */
    Expand: string = "";

    /**
     * @name ShowRefreshOption
     * @description
     * When true a table refresh option is displayed.
     * @servertype Boolean
     * @type {boolean}
     */
    ShowRefreshOption: boolean = false;

    /**
     * @name HeaderClickUrl
     * @description
     * If sortMode is 'none' and a url is provided then header click will route to the specified url.  The url can contain the following
     * macros: {{ClickedPropertyName}} or {{ClickedHeaderLabel}}.
     * @servertype String
     * @type {string}
     */
    HeaderClickUrl: string = "";

    /**
     * @name HeaderClickUrlNewWindow
     * @description
     * When true the HeaderClickUrl is opened in a new window.
     * @servertype Boolean
     * @type {boolean}
     */
    HeaderClickUrlNewWindow: boolean = false;

    /**
     * @name FilterColumns
     * @description
     * Each column has it's own filter type (including none) but this flag can globally turn of column filtering.
     * @servertype Boolean
     * @type {boolean}
     */
    FilterColumns: boolean = false;

    /**
     * @name ColumnResizeMode
     * @description
     * Mode to use for resizing columns.  Possible options include: none, fit, expand
     * @servertype String
     * @type {string}
     */
    ColumnResizeMode: string = "";

    /**
     * @name Responsive
     * @description
     * When true the table is responsive and columns will be stacked on mobile devices.
     * @servertype Boolean
     * @type {boolean}
     */
    Responsive: boolean = false;

    /**
     * @name Theme
     * @description
     * The table theme to apply.  Possible options include: default, plain, striped.
     * @servertype String
     * @type {string}
     */
    Theme: string = "";

    /**
     * @name RowStyle
     * @description
     * An optional CSS style to apply to all rows.
     * @servertype String
     * @type {string}
     */
    RowStyle: string = "";

    /**
     * @name GetRowStyle
     * @servertype String
     * @type {string}
     */
    GetRowStyle: string = "";

    /**
     * @name RowClickUrl
     * @servertype String
     * @type {string}
     */
    RowClickUrl: string = "";

    /**
     * @name RowClickUrlNewWindow
     * @servertype Boolean
     * @type {boolean}
     */
    RowClickUrlNewWindow: boolean = false;

    /**
     * @name ShowLoader
     * @servertype Boolean
     * @type {boolean}
     */
    ShowLoader: boolean = false;

    /**
     * @name Columns
     * @servertype TableColumnConfigurationViewModel array
     * @type {TableColumnConfigurationViewModel[]}
     */
    Columns: TableColumnConfigurationViewModel[] = [];

    /**
     * @name RowActionIds
     * @servertype string array
     * @type {string[]}
     */
    RowActionIds: string[] = [];

    /**
     * @name RowActions
     * @servertype TableActionConfigurationViewModel array
     * @type {TableActionConfigurationViewModel[]}
     */
    RowActions: TableActionConfigurationViewModel[] = [];

    /**
     * @name RowActionDisplayMode
     * @servertype String
     * @type {string}
     */
    RowActionDisplayMode: string = "";

    /**
     * @name RowActionsForIconDisplay
     * @servertype string array
     * @type {string[]}
     */
    RowActionsForIconDisplay: string[] = [];

    /**
     * @name RememberLayout
     * @servertype Boolean
     * @type {boolean}
     */
    RememberLayout: boolean = false;

    /**
     * @name RememberSort
     * @servertype Boolean
     * @type {boolean}
     */
    RememberSort: boolean = false;

    /**
     * @name RememberFilter
     * @servertype Boolean
     * @type {boolean}
     */
    RememberFilter: boolean = false;

    /**
     * @name RememberPaging
     * @servertype Boolean
     * @type {boolean}
     */
    RememberPaging: boolean = false;

    /**
     * @name RememberDateRange
     * @servertype Boolean
     * @type {boolean}
     */
    RememberDateRange: boolean = false;

    /**
     * @name Comments
     * @description
     * Comments regarding this table configuration.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Table Column Configuration
 * @description
 * Information about a table column configuration.
 */
export class TableColumnConfigurationViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name TableColumnId
     * @servertype String
     * @type {string}
     */
    TableColumnId: string = "";

    /**
     * @name PropertyName
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

    /**
     * @name SupportingPropertyName
     * @servertype String
     * @type {string}
     */
    SupportingPropertyName: string = "";

    /**
     * @name HeaderIcon
     * @servertype String
     * @type {string}
     */
    HeaderIcon: string = "";

    /**
     * @name HeaderLabel
     * @servertype String
     * @type {string}
     */
    HeaderLabel: string = "";

    /**
     * @name HeaderDescription
     * @servertype String
     * @type {string}
     */
    HeaderDescription: string = "";

    /**
     * @name Visible
     * @servertype Boolean
     * @type {boolean}
     */
    Visible: boolean = false;

    /**
     * @name Sortable
     * @servertype Boolean
     * @type {boolean}
     */
    Sortable: boolean = false;

    /**
     * @name Resizable
     * @servertype Boolean
     * @type {boolean}
     */
    Resizable: boolean = false;

    /**
     * @name WordWrap
     * @servertype Boolean
     * @type {boolean}
     */
    WordWrap: boolean = false;

    /**
     * @name AllowLineBreaks
     * @servertype Boolean
     * @type {boolean}
     */
    AllowLineBreaks: boolean = false;

    /**
     * @name AllowHtml
     * @servertype Boolean
     * @type {boolean}
     */
    AllowHtml: boolean = false;

    /**
     * @name DataType
     * @servertype String
     * @type {string}
     */
    DataType: string = "";

    /**
     * @name Decimals
     * @servertype Int32
     * @type {number}
     */
    Decimals: number = 0;

    /**
     * @name MaxCharacterLength
     * @servertype Int32
     * @type {number}
     */
    MaxCharacterLength: number = 0;

    /**
     * @name ToolTipWhenMaxCharacterLengthExceeded
     * @servertype Boolean
     * @type {boolean}
     */
    ToolTipWhenMaxCharacterLengthExceeded: boolean = false;

    /**
     * @name Align
     * @servertype String
     * @type {string}
     */
    Align: string = "";

    /**
     * @name ToolTipType
     * @servertype String
     * @type {string}
     */
    ToolTipType: string = "";

    /**
     * @name ToolTipTitle
     * @servertype String
     * @type {string}
     */
    ToolTipTitle: string = "";

    /**
     * @name ToolTipText
     * @servertype String
     * @type {string}
     */
    ToolTipText: string = "";

    /**
     * @name ToolTipTitleFunction
     * @servertype String
     * @type {string}
     */
    ToolTipTitleFunction: string = "";

    /**
     * @name ToolTipTextFunction
     * @servertype String
     * @type {string}
     */
    ToolTipTextFunction: string = "";

    /**
     * @name ToolTipAppendToBody
     * @servertype Boolean
     * @type {boolean}
     */
    ToolTipAppendToBody: boolean = false;

    /**
     * @name ToolTipPlacement
     * @servertype String
     * @type {string}
     */
    ToolTipPlacement: string = "";

    /**
     * @name Width
     * @servertype String
     * @type {string}
     */
    Width: string = "";

    /**
     * @name FooterHtml
     * @servertype String
     * @type {string}
     */
    FooterHtml: string = "";

    /**
     * @name HeaderClickUrl
     * @servertype String
     * @type {string}
     */
    HeaderClickUrl: string = "";

    /**
     * @name HeaderClickUrlNewWindow
     * @servertype Boolean
     * @type {boolean}
     */
    HeaderClickUrlNewWindow: boolean = false;

    /**
     * @name RowClickUrl
     * @servertype String
     * @type {string}
     */
    RowClickUrl: string = "";

    /**
     * @name RowClickUrlNewWindow
     * @servertype Boolean
     * @type {boolean}
     */
    RowClickUrlNewWindow: boolean = false;

    /**
     * @name Render
     * @servertype String
     * @type {string}
     */
    Render: string = "";

    /**
     * @name SupportLateRender
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLateRender: boolean = false;

    /**
     * @name Style
     * @servertype String
     * @type {string}
     */
    Style: string = "";

    /**
     * @name GetStyle
     * @servertype String
     * @type {string}
     */
    GetStyle: string = "";

    /**
     * @name OptionsPickListId
     * @servertype String
     * @type {string}
     */
    OptionsPickListId: string = "";

    /**
     * @name OptionsPickList
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    OptionsPickList: {[key: string]:  string} = {};

    /**
     * @name OptionsIncludeNone
     * @servertype Boolean
     * @type {boolean}
     */
    OptionsIncludeNone: boolean = false;

    /**
     * @name OptionsNoneLabel
     * @servertype String
     * @type {string}
     */
    OptionsNoneLabel: string = "";

    /**
     * @name IncludeInGlobalFilter
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeInGlobalFilter: boolean = false;

    /**
     * @name FilterType
     * @servertype String
     * @type {string}
     */
    FilterType: string = "";

    /**
     * @name FilterMatchMode
     * @servertype String
     * @type {string}
     */
    FilterMatchMode: string = "";

    /**
     * @name FilterPickListId
     * @servertype String
     * @type {string}
     */
    FilterPickListId: string = "";

    /**
     * @name FilterPickListValues
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    FilterPickListValues: {[key: string]:  string} = {};

}



/**
 * @name Table Action Configuration
 * @description
 * Information about a table action configuration.
 */
export class TableActionConfigurationViewModel {

    /**
     * @name ActionId
     * @servertype String
     * @type {string}
     */
    ActionId: string = "";

    /**
     * @name RequiresRole
     * @servertype String
     * @type {string}
     */
    RequiresRole: string = "";

    /**
     * @name RequiresPermissionAccessArea
     * @servertype String
     * @type {string}
     */
    RequiresPermissionAccessArea: string = "";

    /**
     * @name RequiresPermissionRight
     * @servertype String
     * @type {string}
     */
    RequiresPermissionRight: string = "";

}



/**
 * @name WatchEditViewModel
 * @description
 * The Watch table contains list of people watching objects.
 */
export class WatchEditViewModel {

    /**
     * @name WatchId
     * @description
     * The id of the watch.
     * @servertype Int64
     * @type {number}
     */
    WatchId: number = null;

    /**
     * @name WatchedResourceType
     * @description
     * The watched resource type (e.g. table, etc.) for this watch.
     * @servertype String
     * @type {string}
     */
    WatchedResourceType: string = "";

    /**
     * @name WatchedResourceId
     * @description
     * The watched resource id is the key of the watched resource type for this watch.  For example, if the watched resource type
     * is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WatchedResourceId: number = 0;

    /**
     * @name WatchedResourceId2
     * @description
     * The watched resource id 2 is the key of the watched resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    WatchedResourceId2: string = "";

    /**
     * @name WatchedResourceScope
     * @description
     * A comma delimited list of keywords that are used to limit the scope of what part of the resource is being watched.  For example,
     * a case might have values of 'Changes,Correspondence' to indicate that both changes and correspondence are being watched.
     *  The specific values vary from one resource to the next.  When no value is provided all changes are considered in scope for
     * the resource.
     * @servertype String
     * @type {string}
     */
    WatchedResourceScope: string = "";

    /**
     * @name ContactId
     * @description
     * The contact id that represents who is watching this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name NotificationMethods
     * @description
     * A comma delimited list of notification methods to use.  Note that for non-contact entities only a single notification method
     * is permitted since the target of that notification type is in the contact information property.  Possible values include:
     * * = Include in list of changed items
     * E = Email
     * S = SMS
     * P = Phone Call
     * F = Fax
     * @servertype String
     * @type {string}
     */
    NotificationMethods: string = "";

    /**
     * @name ContactInformation
     * @description
     * The email address, phone number, etc. used for notification for non-contact entities watching an object.
     * @servertype String
     * @type {string}
     */
    ContactInformation: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name WebApiRequestViewModel
 */
export class WebApiRequestViewModel {

    /**
     * @name Url
     * @description
     * The url used for the webhook.
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name Method
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name MethodCustom
     * @servertype String
     * @type {string}
     */
    MethodCustom: string = "";

    /**
     * @name Headers
     * @servertype KeyValuePairModel<string, string> array
     * @type {m.KeyValuePairModel<string,string>[]}
     */
    Headers: m.KeyValuePairModel<string,string>[] = [];

    /**
     * @name Body
     * @servertype String
     * @type {string}
     */
    Body: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name Accept
     * @servertype String
     * @type {string}
     */
    Accept: string = "";

    /**
     * @name UserAgent
     * @servertype String
     * @type {string}
     */
    UserAgent: string = "";

    /**
     * @name UserName
     * @servertype String
     * @type {string}
     */
    UserName: string = "";

    /**
     * @name Password
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name SourceIpAddress
     * @servertype String
     * @type {string}
     */
    SourceIpAddress: string = "";

    /**
     * @name Protocol
     * @servertype String
     * @type {string}
     */
    Protocol: string = "";

    /**
     * @name AlwaysTrustCertificate
     * @servertype Boolean
     * @type {boolean}
     */
    AlwaysTrustCertificate: boolean = false;

    /**
     * @name Compression
     * @servertype String
     * @type {string}
     */
    Compression: string = "";

    /**
     * @name TimeoutMilliseconds
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TimeoutMilliseconds: number = 0;

    /**
     * @name ExceptionHandling
     * @servertype String
     * @type {string}
     */
    ExceptionHandling: string = "";

    /**
     * @name ErrorResponseWhenBodyContains
     * @servertype String
     * @type {string}
     */
    ErrorResponseWhenBodyContains: string = "";

    /**
     * @name ErrorResponseWhenBodyMatchesRegex
     * @servertype String
     * @type {string}
     */
    ErrorResponseWhenBodyMatchesRegex: string = "";

}



/**
 * @name WebhookEditViewModel
 * @description
 * The Webhook table contains information about webhooks that enable external systems to be notified of events.
 */
export class WebhookEditViewModel {

    /**
     * @name WebhookId
     * @description
     * Webhook Id uniquely identifies this webhook.
     * @servertype Int64
     * @type {number}
     */
    WebhookId: number = null;

    /**
     * @name ExternalWebhookId
     * @description
     * An optional external webhook id for this webhook.
     * @servertype String
     * @type {string}
     */
    ExternalWebhookId: string = "";

    /**
     * @name Description
     * @description
     * Description for this webhook.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Type
     * @description
     * The webhook type.  This is especially important for incoming webhooks where the type is part of the target url.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Enabled
     * @description
     * True indicates the webhook is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Incoming
     * @description
     * True indicates the webhook is an incoming webhook.
     * @servertype Boolean
     * @type {boolean}
     */
    Incoming: boolean = false;

    /**
     * @name Url
     * @description
     * A list of one or more urls to which the webhook event data will be delivered.  For security reasons this should be a secure
     * endpoint either via https, vpn, or private lan.  Because anyone can theoretically POST a payload to the url, we sign messages.
     * Event headers for messages contain the signature and information that you can use to validate the signature as described
     * in the documentation.
     * Webhook endpoints may occasionally receive the same event more than once.  Guard against duplicated event receipts by making
     * event processing idempotent. One way of doing this is logging the events processed, and then not processing already-logged
     * events.
     * Note that this is N/A for incoming webhook events as incoming webhook events are all sent to a fixed url that is distinguished
     * from other incoming webhooks by the type property.
     * @servertype string array
     * @type {string[]}
     */
    Url: string[] = [];

    /**
     * @name RoundRobin
     * @description
     * True indicates the list of urls are utilized in round-robin fashion.  When false the urls in the list are utilized as failover
     * options if previous urls in the list are unsuccessful.
     * @servertype Boolean
     * @type {boolean}
     */
    RoundRobin: boolean = false;

    /**
     * @name SmartRouting
     * @description
     * True indicates smart routing will be used when selecting a url from the list of urls.  This allows things like failures to
     * result in a url being less frequently used.
     * @servertype Boolean
     * @type {boolean}
     */
    SmartRouting: boolean = false;

    /**
     * @name TimeoutSeconds
     * @description
     * Number of seconds after which webhook delivery will timeout and fail or get requeued for retry.  0 means no timeout.  Note
     * that the system may impose it's own minimum or maximum timeouts.
     * @servertype Int32
     * @type {number}
     */
    TimeoutSeconds: number = 0;

    /**
     * @name EventTypes
     * @description
     * A list of event types this webhook is triggered for.  Use * for wildcards.  Formatting generally follows a pattern of dot
     * delimited sections in format {category}.{item}.{operation}.{success} to identify the event type.
     * For api endpoints the pattern is api.{endpoint-path}.{type}.{result} as shown in these examples:
     * api.* = all api events
     * api.billing/purchases.* = all api billing purchases events
     * api.billing/purchases.add.* = all api billing purchases add events
     * api.billing/purchases.add.success = successful api billing purchases add events
     * api.billing/purchases.add.fail = failed api billing purchases add events
     * @servertype string array
     * @type {string[]}
     */
    EventTypes: string[] = [];

    /**
     * @name EventFilters
     * @description
     * A list of filters that can be used to determine if a payload should be part of webhook delivery.  This can be used to further
     * restrict what is included beyond event types.
     * @servertype String
     * @type {string}
     */
    EventFilters: string = "";

    /**
     * @name ContentType
     * @description
     * The content type to use for serializing data.  Possible values include:
     * JSON = json
     * XML = xml
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name Secret
     * @description
     * An optional string used to create a signature for webhook posts.  If no secret is provided a public/private key is used for
     * signing messages as provided for with the signature encryption key tag.
     * @servertype String
     * @type {string}
     */
    Secret: string = "";

    /**
     * @name Login
     * @description
     * An optional login to use for basic access authentication.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Password
     * @description
     * An optional password to use for basic access authentication.
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name Bearer
     * @description
     * An optional bearer token to use for bearer access authentication.
     * @servertype String
     * @type {string}
     */
    Bearer: string = "";

    /**
     * @name SignatureEncryptionKeyServerId
     * @description
     * The id of the encryption key management server used for signature keys.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SignatureEncryptionKeyServerId: number = 0;

    /**
     * @name SignatureEncryptionKeyTag
     * @description
     * The encryption key tag provided by the key management server used for signature keys.
     * @servertype String
     * @type {string}
     */
    SignatureEncryptionKeyTag: string = "";

    /**
     * @name Configuration
     * @description
     * A list of key/value pairs that provide settings for this webhook.  For example:
     * SourceIpAddress = A CSV of ip addresses that can be used as the source ip address for outgoing webhooks.
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    Configuration: {[key: string]:  any} = {};

    /**
     * @name Headers
     * @description
     * A list of key/value pairs that are included as headers in messages sent to webhook url.
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Headers: {[key: string]:  string} = {};

    /**
     * @name Format
     * @description
     * For custom payload formats this is the format mapping.  When used this typically requires this webhook to subscribe to a
     * limited number of event types that support the same format map.
     * @servertype String
     * @type {string}
     */
    Format: string = "";

    /**
     * @name FormatDataSource
     * @description
     * When format mapping is used this documents the data source for that mapping.
     * @servertype String
     * @type {string}
     */
    FormatDataSource: string = "";

    /**
     * @name ResponseFormat
     * @description
     * This is the response format mapping.  For incoming webhooks this represents the format of the response to send back.  For
     * outgoing webhooks this is typically N/A since the http status code is used to determine the results but in complex scenarios
     * this can be used to help interpret the response body.
     * @servertype String
     * @type {string}
     */
    ResponseFormat: string = "";

    /**
     * @name ResponseFormatDataSource
     * @description
     * When response format mapping is used this documents the data source for that mapping.
     * @servertype String
     * @type {string}
     */
    ResponseFormatDataSource: string = "";

    /**
     * @name SignatureData
     * @description
     * The signature data to use.  Possible values include:
     *  = None
     * Body = Body
     * Keys = Keys (TransmissionId|TransmissionTime|WebhookId|PayloadCrc32)
     * @servertype String
     * @type {string}
     */
    SignatureData: string = "";

    /**
     * @name SignatureAlgorithm
     * @description
     * The signature algorithm to use.  The url receiving the post via this webhook must verify that messages originated from the
     * system, were not altered or corrupted during transmission, were targeted for the receiver, and contain a valid signature.
     * To validate the signature, use the information in event headers.  Possible signature algorithm values include:
     * HMAC = HMAC
     * @servertype String
     * @type {string}
     */
    SignatureAlgorithm: string = "";

    /**
     * @name NotificationGroupId
     * @description
     * Notification group to notify when webhook event occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name CaseTemplateId
     * @description
     * Case template for case to create when webhook event occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = null;

    /**
     * @name ProcessTemplateId
     * @description
     * Process template for process to trigger when webhook event occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = null;

    /**
     * @name ProcessPeerId
     * @description
     * For processes that must execute peer operations chronologically this is a peer id used to identify peers.  When not specified
     * the process template id will be used to identify peers but providing a value here allows more definitive identification of
     * peers for scenarios where a template is used by non-peers.
     * @servertype String
     * @type {string}
     */
    ProcessPeerId: string = "";

    /**
     * @name TriggerAssetId
     * @description
     * The Asset Id to use for trigger to execute for processing event (incoming) or event response (outgoing).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = null;

    /**
     * @name TriggerJobType
     * @description
     * A job type to trigger as one time execution job when webhook event occurs.  If a recurring job is to be run specify that
     * as the trigger job id.
     * @servertype String
     * @type {string}
     */
    TriggerJobType: string = "";

    /**
     * @name TriggerJobId
     * @description
     * The Job Id of a recurring job to execute when webhook event occurs.  If a one time execution job is to be run specify that
     * as the trigger job type.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerJobId: number = null;

    /**
     * @name TriggerPlugin
     * @description
     * A plugin to execute when webhook event occurs.
     * @servertype String
     * @type {string}
     */
    TriggerPlugin: string = "";

    /**
     * @name RealTimeDelivery
     * @description
     * True indicates the webhook requests real-time delivery when possible.
     * @servertype Boolean
     * @type {boolean}
     */
    RealTimeDelivery: boolean = false;

    /**
     * @name RetryIntervalMinutes
     * @description
     * The number of minutes to wait between retry for failed delivery.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryIntervalMinutes: number = 0;

    /**
     * @name RetryIntervalMinutesList
     * @description
     * A CSV of minutes to use for retry of failed webhook events.  This supports varying retry intervals.  For example, 5, 30,
     * 60 would retry after 5 minutes, then 30 minutes, then every 60 minutes until the retry limit minutes has been reached.
     * @servertype int array
     * @type {number[]}
     */
    RetryIntervalMinutesList: number[] = [];

    /**
     * @name RetryLimitMinutes
     * @description
     * The maximum number of minutes to attempt delivery.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryLimitMinutes: number = 0;

    /**
     * @name Trace
     * @description
     * True indicates trace information should be logged with the webhook event.
     * @servertype Boolean
     * @type {boolean}
     */
    Trace: boolean = false;

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name WebhookEventEditViewModel
 * @description
 * The Webhook Event table contains information on webhook events triggered during the YYYYMM month.
 */
export class WebhookEventEditViewModel {

    /**
     * @name WebhookEventId
     * @description
     * Unique value that represents this notification event record.
     * @servertype String
     * @type {string}
     */
    WebhookEventId: string = null;

    /**
     * @name EventTag
     * @description
     * An event tag that can be used in combination with the event key to validate external links to the event for things like confirmation,
     * etc. to verify that a link wasn't guessed.
     * @servertype String
     * @type {string}
     */
    EventTag: string = "";

    /**
     * @name EventDateTime
     * @description
     * Date and time when the event happened.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = null;

    /**
     * @name ResourceType
     * @description
     * The type of resource this event is about.
     * @servertype String
     * @type {string}
     */
    ResourceType: string = "";

    /**
     * @name ResourceId
     * @description
     * The resource id is the key of the resource type for this event.  For example, if the resource type is InventoryType this
     * value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ResourceId: number = 0;

    /**
     * @name ResourceId2
     * @description
     * The resource id 2 is the key of the resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    ResourceId2: string = "";

    /**
     * @name EventType
     * @description
     * The type of event this event is about.
     * @servertype String
     * @type {string}
     */
    EventType: string = "";

    /**
     * @name Description
     * @description
     * The description of this event.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name WebhookId
     * @description
     * Webhook Id identifies the webhook that this event is targeting.
     * @servertype Int64
     * @type {number}
     */
    WebhookId: number = null;

    /**
     * @name EventData
     * @description
     * Event data.  For outgoing webhooks this is the data being sent.  For incoming webhooks this is the data that was received.
     * Note that for outgoing webhooks the structure of an Event object sent in a webhook is dictated by the API version used at
     * the time of the event's occurrence.  Even if that API version changes at a future date the Event object generated still aligns
     * with the API version at the time of the event's occurrence.
     * Likewise, the contents of the event data are current at the time of the event's occurrence and is not changed to reflect
     * other more recent data changes.  This combined with possible delivery delays means an object that is edited multiple times
     * could have those webhook events arrive out of sequence.  The process run at the webhook url needs to use information from
     * the event payload to determine if it is receiving the most up-to-date information about an object and to act accordingly.
     * 
     * Event objects will never be changed after the fact.  Subsequent updates to the api version or source data do not retroactively
     * alter existing event objects.
     * @servertype String
     * @type {string}
     */
    EventData: string = "";

    /**
     * @name ResponseData
     * @description
     * The response from the webhook.  For outgoing web hooks this is the most recent response body received from the delivery (see
     * delivery history) for incoming web hooks this is the response that was sent.
     * @servertype String
     * @type {string}
     */
    ResponseData: string = "";

    /**
     * @name NotificationEventDateTime
     * @description
     * If a notification group was notified this is the date and time when the notification event was triggered.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationEventDateTime: Date = null;

    /**
     * @name NotificationEventId
     * @description
     * If a notification group was notified this is the notification event id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationEventId: number = null;

    /**
     * @name CaseId
     * @description
     * If a case was created this is the case id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseId: number = null;

    /**
     * @name ProcessDateTime
     * @description
     * If a process was triggered this is the date and time when the process was created.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessDateTime: Date = null;

    /**
     * @name ProcessId
     * @description
     * If a process was triggered this is the process id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessId: number = null;

    /**
     * @name JobId
     * @description
     * If a job was executed this is the job id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    JobId: number = null;

    /**
     * @name PluginResultCode
     * @description
     * If a plugin was executed this is the result code.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PluginResultCode: number = 0;

    /**
     * @name AssetResultCode
     * @description
     * If an asset was executed this is the result code.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AssetResultCode: number = 0;

    /**
     * @name Status
     * @description
     * Status of this webhook event.  Possible values are:
     * P = Pending (webhook not sent yet or is pending retry)
     * R = Running (webhook is being sent)
     * A = Abandoned (webhook was abandoned and will be updated as failed or requeued as pending)
     * S = Success (webhook was successful)
     * F = Failure (webhook failed and will not be retried)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name QueueDateTime
     * @description
     * The date and time this record is queued for.  This defaults to the same as event date and time but can be adjusted in situations
     * where the record needs to be processed later.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QueueDateTime: Date = null;

    /**
     * @name DeliveryAttemptCount
     * @description
     * Number of times delivery was attempted.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DeliveryAttemptCount: number = 0;

    /**
     * @name LastDeliveryAttemptDateTime
     * @description
     * Date and time when the webhook delivery engine last attempted delivery.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastDeliveryAttemptDateTime: Date = null;

    /**
     * @name DeliveryHistory
     * @description
     * Delivery history for this webhook event.  When the webhook target url receives the message, it must respond with a HTTP 200-level
     * status code.  If the response has any other status code, the message will be queued for retry based on the settings in the
     * webhook which are typically limited to a retry every hour for a few days.
     * If the webhook url performs complex logic, or makes network calls, it's possible the POST would timeout before that process
     * completes. For that reason, webhook target urls should immediately acknowledge receipt by returning a 2xx HTTP status code,
     * and then perform the rest of its duties.
     * The delivery history includes a transmission id, the time of the delivery attempt, the http status code returned (where 0
     * would indicate a timeout) and the duration in milliseconds stored in json format that looks like this:
     * [ { "Id": "avopi3d" , "Time": "2016-10-24T14:14:14" , "ResponseCode": 200 , "ResponseMessage": "Success" , "Duration": 418
     * , "Server": "AppServer1" , "Url": "https://sampletargeturl.com/post"  } ]
     * @servertype WebhookEventDeliveryEditViewModel array
     * @type {WebhookEventDeliveryEditViewModel[]}
     */
    DeliveryHistory: WebhookEventDeliveryEditViewModel[] = [];

    /**
     * @name ProcessingServer
     * @description
     * The server processing this webhook request.  If a webhook delivery engine starts processing a request but never updates to
     * reflect the results there was most likely a serious failure that should have been logged on the processing server.
     * @servertype String
     * @type {string}
     */
    ProcessingServer: string = "";

    /**
     * @name ProcessingDateTime
     * @description
     * Date and time when the webhook delivery engine started processing this webhook event.  This can be used to help determine
     * if there are any webhook events that should have posted success or failure but haven't and may be orphaned.  In that scenario
     * the processing server should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name Trace
     * @description
     * Any error messages plus verbose trace information populated when requested.
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name WebhookEventDeliveryEditViewModel
 */
export class WebhookEventDeliveryEditViewModel {

    /**
     * @name Id
     * @description
     * A unique id for the delivery attempt.
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Time
     * @description
     * The date and time of the delivery attempt.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    Time: Date = null;

    /**
     * @name ResponseCode
     * @description
     * The http status code response to the delivery.  Zero equates to a timeout.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResponseCode: number = 0;

    /**
     * @name ResponseMessage
     * @description
     * The http status message response to the delivery (if any).
     * @servertype String
     * @type {string}
     */
    ResponseMessage: string = "";

    /**
     * @name DurationMilliseconds
     * @description
     * A duration of the delivery attempt in milliseconds.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DurationMilliseconds: number = 0;

    /**
     * @name Server
     * @description
     * The server processing the delivery attempt.
     * @servertype String
     * @type {string}
     */
    Server: string = "";

    /**
     * @name Url
     * @description
     * The url used for the delivery attempt.
     * @servertype String
     * @type {string}
     */
    Url: string = "";

}



/**
 * @name Webhook Event List
 * @description
 * The Webhook Event table contains information on webhook events triggered during the YYYYMM month.
 */
export class WebhookEventListViewModel {

    /**
     * @name WebhookEventId
     * @description
     * Unique value that represents this notification event record.
     * @servertype String
     * @type {string}
     */
    WebhookEventId: string = null;

    /**
     * @name EventTag
     * @description
     * An event tag that can be used in combination with the event key to validate external links to the event for things like confirmation,
     * etc. to verify that a link wasn't guessed.
     * @servertype String
     * @type {string}
     */
    EventTag: string = "";

    /**
     * @name EventDateTime
     * @description
     * Date and time when the event happened.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = null;

    /**
     * @name ResourceType
     * @description
     * The type of resource this event is about.
     * @servertype String
     * @type {string}
     */
    ResourceType: string = "";

    /**
     * @name ResourceId
     * @description
     * The resource id is the key of the resource type for this event.  For example, if the resource type is InventoryType this
     * value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ResourceId: number = 0;

    /**
     * @name ResourceId2
     * @description
     * The resource id 2 is the key of the resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    ResourceId2: string = "";

    /**
     * @name EventType
     * @description
     * The type of event this event is about.
     * @servertype String
     * @type {string}
     */
    EventType: string = "";

    /**
     * @name Description
     * @description
     * The description of this event.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name WebhookId
     * @description
     * Webhook Id identifies the webhook that this event is targeting.
     * @servertype Int64
     * @type {number}
     */
    WebhookId: number = null;

    /**
     * @name NotificationEventDateTime
     * @description
     * If a notification group was notified this is the date and time when the notification event was triggered.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationEventDateTime: Date = null;

    /**
     * @name NotificationEventId
     * @description
     * If a notification group was notified this is the notification event id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationEventId: number = null;

    /**
     * @name CaseId
     * @description
     * If a case was created this is the case id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseId: number = null;

    /**
     * @name ProcessDateTime
     * @description
     * If a process was triggered this is the date and time when the process was created.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessDateTime: Date = null;

    /**
     * @name ProcessId
     * @description
     * If a process was triggered this is the process id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessId: number = null;

    /**
     * @name JobId
     * @description
     * If a job was executed this is the job id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    JobId: number = null;

    /**
     * @name PluginResultCode
     * @description
     * If a plugin was executed this is the result code.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PluginResultCode: number = 0;

    /**
     * @name AssetResultCode
     * @description
     * If an asset was executed this is the result code.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AssetResultCode: number = 0;

    /**
     * @name Status
     * @description
     * Status of this webhook event.  Possible values are:
     * P = Pending (webhook not sent yet or is pending retry)
     * R = Running (webhook is being sent)
     * A = Abandoned (webhook was abandoned and will be updated as failed or requeued as pending)
     * S = Success (webhook was successful)
     * F = Failure (webhook failed and will not be retried)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name QueueDateTime
     * @description
     * The date and time this record is queued for.  This defaults to the same as event date and time but can be adjusted in situations
     * where the record needs to be processed later.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QueueDateTime: Date = null;

    /**
     * @name DeliveryAttemptCount
     * @description
     * Number of times delivery was attempted.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DeliveryAttemptCount: number = 0;

    /**
     * @name LastDeliveryAttemptDateTime
     * @description
     * Date and time when the webhook delivery engine last attempted delivery.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastDeliveryAttemptDateTime: Date = null;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name WebhookListViewModel
 * @description
 * The Webhook table contains information about webhooks that enable external systems to be notified of events.
 */
export class WebhookListViewModel {

    /**
     * @name WebhookId
     * @description
     * Webhook Id uniquely identifies this webhook.
     * @servertype Int64
     * @type {number}
     */
    WebhookId: number = null;

    /**
     * @name ExternalWebhookId
     * @description
     * An optional external webhook id for this webhook.
     * @servertype String
     * @type {string}
     */
    ExternalWebhookId: string = "";

    /**
     * @name Description
     * @description
     * Description for this webhook.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Type
     * @description
     * The webhook type.  This is especially important for incoming webhooks where the type is part of the target url.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Enabled
     * @description
     * True indicates the webhook is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Incoming
     * @description
     * True indicates the webhook is an incoming webhook.
     * @servertype Boolean
     * @type {boolean}
     */
    Incoming: boolean = false;

    /**
     * @name Url
     * @description
     * A list of one or more urls to which the webhook event data will be delivered.  For security reasons this should be a secure
     * endpoint either via https, vpn, or private lan.  Because anyone can theoretically POST a payload to the url, we sign messages.
     * Event headers for messages contain the signature and information that you can use to validate the signature as described
     * in the documentation.
     * Webhook endpoints may occasionally receive the same event more than once.  Guard against duplicated event receipts by making
     * event processing idempotent. One way of doing this is logging the events processed, and then not processing already-logged
     * events.
     * Note that this is N/A for incoming webhook events as incoming webhook events are all sent to a fixed url that is distinguished
     * from other incoming webhooks by the type property.
     * @servertype string array
     * @type {string[]}
     */
    Url: string[] = [];

    /**
     * @name EventTypes
     * @description
     * A list of event types this webhook is triggered for.  Use * for wildcards.  Formatting generally follows a pattern of dot
     * delimited sections in format {category}.{item}.{operation}.{success} to identify the event type.
     * For api endpoints the pattern is api.{endpoint-path}.{type}.{result} as shown in these examples:
     * api.* = all api events
     * api.billing/purchases.* = all api billing purchases events
     * api.billing/purchases.add.* = all api billing purchases add events
     * api.billing/purchases.add.success = successful api billing purchases add events
     * api.billing/purchases.add.fail = failed api billing purchases add events
     * @servertype string array
     * @type {string[]}
     */
    EventTypes: string[] = [];

    /**
     * @name RealTimeDelivery
     * @description
     * True indicates the webhook requests real-time delivery when possible.
     * @servertype Boolean
     * @type {boolean}
     */
    RealTimeDelivery: boolean = false;

    /**
     * @name RetryIntervalMinutes
     * @description
     * The number of minutes to wait between retry for failed delivery.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryIntervalMinutes: number = 0;

    /**
     * @name RetryLimitMinutes
     * @description
     * The maximum number of minutes to attempt delivery.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryLimitMinutes: number = 0;

    /**
     * @name Trace
     * @description
     * True indicates trace information should be logged with the webhook event.
     * @servertype Boolean
     * @type {boolean}
     */
    Trace: boolean = false;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



